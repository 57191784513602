import React, { Dispatch, SetStateAction } from 'react';
import { AppState } from 'react-native';
import NetInfo from '@react-native-community/netinfo';

interface AppStateContextType {
  appStateVisible: any;
  needToTapePinCode: boolean;
  isConnected: boolean;
  setNeedToTapePinCode: Dispatch<SetStateAction<boolean>>;
}

const AppStateContext = React.createContext({} as AppStateContextType);

const AppStateProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const appState = React.useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = React.useState<string>(appState.current);
  const [needToTapePinCode, setNeedToTapePinCode] = React.useState<boolean>(false);
  const [isConnected, setIsConnected] = React.useState<any>(true);

  React.useEffect(() => {
    AppState.addEventListener('change', _handleAppStateChange);
    return () => {
      AppState.removeEventListener('change', _handleAppStateChange);
    };
  }, []);

  React.useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setIsConnected(state.isConnected);
    });

    return () => unsubscribe();
  }, []);

  const _handleAppStateChange = (nextAppState: string) => {
    setAppStateVisible(nextAppState);
    nextAppState === 'inactive' || nextAppState === 'background'
      ? setNeedToTapePinCode(true)
      : setNeedToTapePinCode(false);
  };

  return (
    <AppStateContext.Provider value={{ appStateVisible, needToTapePinCode, isConnected, setNeedToTapePinCode }}>
      {children}
    </AppStateContext.Provider>
  );
};

const useAppStateContext = () => {
  return React.useContext(AppStateContext);
};

export { AppStateProvider, useAppStateContext };
