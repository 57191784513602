import actionObject from './actionObjects';

const featuresActions = {
  //objects (actions strings & payload)
  setFeatures: (payload: any) => {
    return actionObject.create('setFeatures', payload);
  },
  SET_FEATURES: actionObject.create('setFeatures').type,
};

export default featuresActions;
