import { gql } from '@apollo/client';

const releasePositionMutation = gql`
  mutation releasePosition($positionId: Int!) {
    releasePosition(positionId: $positionId) {
      status
    }
  }
`;
export default releasePositionMutation;
