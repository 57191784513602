import React from 'react';
import { StyleProp, StyleSheet, TextInput, View } from 'react-native';
import { InputLabel } from '../texts/InputLabel';
import { Colors, Spacing } from '../../styles/theme/';

import { AntDesign } from '@expo/vector-icons';

import { StyleType } from '../../types/styleSheetTypes';

interface InputTextProps {
  label: string;
  password?: boolean;
  success?: boolean;
  error?: boolean;
  filled?: boolean;
  onChangeText?: (value: string) => void;
  value?: string;
  dontCapitalize?: boolean;
  noSpace?: boolean;
  numeric?: boolean;
  onSubmitEditing?: () => any | undefined;
}

export const InputText: React.FunctionComponent<InputTextProps> = ({
  label,
  password,
  success,
  error,
  filled,
  value,
  onChangeText,
  dontCapitalize,
  noSpace,
  numeric,
  onSubmitEditing,
}: InputTextProps) => {
  const [show, setShow] = React.useState(true);
  const inputStyles: Array<StyleProp<StyleType>> = [styles.inputText];
  const containerStyles: Array<StyleProp<StyleType>> = [styles.container];

  const getIcon = () => {
    if (password && !show)
      return (
        <AntDesign name="eyeo" size={24} color={Colors.PRIMARY} style={styles.icon} onPress={() => setShow(true)} />
      );
    if (password && show)
      return (
        <AntDesign name="eye" size={24} color={Colors.PRIMARY} style={styles.icon} onPress={() => setShow(false)} />
      );
    if (success) return <AntDesign name="checkcircleo" size={24} color={Colors.SUCCESS} style={styles.icon} />;
    if (error) return <AntDesign name="closecircleo" size={24} color={Colors.DANGER} style={styles.icon} />;
  };

  const getValue = () => {
    if (!value) return;
    if (noSpace) return value.replace(/ /g, '');
    return value;
  };

  if (error) inputStyles.push(styles.error);
  if (filled) inputStyles.push(styles.filled);

  return (
    <View style={containerStyles}>
      <TextInput
        autoCapitalize={dontCapitalize ? 'none' : 'sentences'}
        style={inputStyles}
        secureTextEntry={!show}
        onChangeText={onChangeText}
        value={getValue()}
        returnKeyType="done"
        keyboardType={numeric ? 'numeric' : 'default'}
        onSubmitEditing={onSubmitEditing ? onSubmitEditing : () => null}
      />
      {getIcon()}
      <InputLabel filled={filled} error={error}>
        {label}
      </InputLabel>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    ...Spacing.MARGIN_VERTICAL_MEDIUM,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputText: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 15,
    borderColor: Colors.GREY,
    width: '100%',
    height: 60,
    fontSize: 18,
    fontWeight: '300',
    color: 'black',
    ...Spacing.PADDING_HORIZONTAL_SMALL,
  },
  error: {
    borderColor: Colors.DANGER,
  },
  filled: {
    borderColor: Colors.PRIMARY,
  },
  icon: {
    position: 'absolute',
    right: 30,
    top: '30%',
  },
});
