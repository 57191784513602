export const formatDemandFromWebsocket = (demand: any) => {
  return {
    archived: demand.archived,
    bodyPart: demand.bodyPart,
    category: {
      icon: demand.category?.icon,
      id: demand.category?.id,
      name: {
        fr: demand.category?.name?.fr,
      },
    },
    comment: demand.comment,
    createdAt: demand.createdAt,
    id: demand.id,
    intensity: demand.intensity,
    patient: {
      id: demand.patient.id,
      name: demand.patient.name,
    },
    position: {
      id: demand.position?.id,
      name: demand.position?.name,
      room: {
        name: demand.position?.room.name,
      },
    },
    priority: demand.priority.toUpperCase(),
    status: demand.status.toUpperCase(),
    takeAt: demand.takeAt,
    viewByCaregiver: demand.viewByCaregiver,
  };
};
