import React, { Context, createContext, useContext, useReducer } from 'react';
import { ChatStateType } from '../types/stateTypes';

import { ChatService } from '../services/Chat.service';
import { IMessage } from '../types/hospiTeamTypes';
import { ChatReducer, initialChatState } from './reducers/chatReducer';
import { StorageService } from '../services/Storage.service';

interface ChatContextElements {
  chatState: ChatStateType;
  getPassword: () => Promise<any>;
  getMessages: (page: number) => Promise<any>;
  getOldMessages: (page: number) => Promise<any>;
  getLastIdMessage: () => number;
  postMessage: (message: IMessage) => Promise<any>;
  setNewMessage: (message: IMessage) => Promise<any>;
  decryptAndSetNewMessage: (message: IMessage) => Promise<any>;
  numberOfNewMessages: number | undefined;
  setNumberOfNewMessages: React.Dispatch<any>;
  setLastIdMessageToStorage: () => Promise<void>;
  getIdOfLastMessageSeen: () => Promise<string | null>;
}

const ChatContext: Context<ChatContextElements> = createContext({} as ChatContextElements);

const ChatProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [chatState, chatDispatch]: any = useReducer(ChatReducer as any, initialChatState);
  const [numberOfNewMessages, setNumberOfNewMessages] = React.useState<number | undefined>(0);
  const chatService = new ChatService(chatDispatch);
  const storageService = new StorageService();

  const getPassword = () => chatService.getPassword();
  const getMessages = (page: number) => chatService.getMessagesOfDepartment(page);
  const getOldMessages = (page: number) => chatService.getOldMessagesOfDepartment(page);
  const postMessage = (message: IMessage) => chatService.postMessage(message);
  const setNewMessage = (message: IMessage) => chatService.setNewMessage(message);
  const decryptAndSetNewMessage = async (message: IMessage) => {};
  const getLastIdMessage = () => chatService.getLastIdMessage([...chatState.messages]);
  const setLastIdMessageToStorage = async () => {
    const id = getLastIdMessage();
    if (id) await chatService.setLastIdMessageToStorage(id);
  };
  const getIdOfLastMessageSeen = () => {
    return storageService.getIdOfLastMessageSeen().then((response) => {
      return response;
    });
  };

  return (
    <ChatContext.Provider
      value={{
        chatState,
        getPassword,
        getMessages,
        getOldMessages,
        postMessage,
        setNewMessage,
        decryptAndSetNewMessage,
        numberOfNewMessages,
        setNumberOfNewMessages,
        getLastIdMessage,
        setLastIdMessageToStorage,
        getIdOfLastMessageSeen,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

const useChatContext = (): ChatContextElements => {
  return useContext(ChatContext);
};

export { ChatProvider, useChatContext };
