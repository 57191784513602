import React, { useState } from 'react';
import { Dimensions, Image, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useUserContext } from '../../providers/UserProvider';
import { Title } from '../texts';
import { AntDesign } from '@expo/vector-icons';

// @ts-ignore
import Modal from 'modal-react-native-web';
import { isSmallScreen } from '../../utils/helpers/isSmallScreen';

function NavModalWeb() {
  const [isModalVisible, setModalVisible] = useState(false);
  const userContext = useUserContext();

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const renderCardService = (item: any, key: number) => {
    return (
      <View style={styles.touchableContainer} key={key}>
        <TouchableOpacity
          onPress={async () => {
            await userContext.changeCurrentDepartment(item.id);
            setModalVisible(false);
          }}
        >
          <View style={[styles.container, { backgroundColor: `#${item.color}` }]}>
            <View>
              <Image source={require('../../assets/images/Bed.png')} style={{ width: 70, height: 70 }} />
            </View>
            <View style={styles.subContainer}>
              <View>
                <Title h2 mvXS>
                  {`${item.name.fr}`}
                </Title>
              </View>
            </View>
            <View>
              <AntDesign name="right" size={24} color="white" />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View>
      {userContext.userState.profile?.departments?.length > 0 ? (
        <TouchableOpacity onPress={toggleModal}>
          <Title h2 mvM>
            Changer de service
          </Title>
        </TouchableOpacity>
      ) : (
        <></>
      )}

      <Modal visible={isModalVisible} animationType="slide" transparent={true}>
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modal}>
            <View style={styles.modalContentContainer}>
              {userContext.userState.profile?.departments?.map((item: any, key: number) => {
                return renderCardService(item, key);
              })}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
}

export default NavModalWeb;

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: Dimensions.get('screen').height,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  serviceCardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
  },

  touchableContainer: {
    width: '85%',
    marginVertical: 10,
  },
  container: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: isSmallScreen() ? 100 : 120,
    alignSelf: 'center',
    borderRadius: 20,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 18,
  },
  subContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
