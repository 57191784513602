import requestsAction from '../actions/requestsActions';

import { ActionParameterDataType } from '../../types/actionTypes';

export const initialUserDemandsState = {
  isLoading: true,
  demands: [],
  error: null,
};

export const demandsReducer = (state = initialUserDemandsState, action: ActionParameterDataType) => {
  switch (action.type) {
    case requestsAction.GET_SERVICE_REQUESTS:
      return {
        ...state,
        demands: action.payload,
        isLoading: false,
      };
    case requestsAction.UPDATE_SERVICE_REQUESTS:
      return {
        ...state,
        demands: [...state.demands, action.payload],
      };
    case requestsAction.UPDATE_SERVICE_REQUEST: {
      const indexDemandToUpdate = state.demands.findIndex((demand: any) => demand.id === action.payload.id);
      const newState: Array<any> = state.demands;
      newState[indexDemandToUpdate] = action.payload;
      return {
        ...state,
        demands: [...newState],
      };
    }
    case requestsAction.RESET_SERVICE_STATE: {
      return {
        demands: initialUserDemandsState.demands,
      };
    }
    default:
      return state;
  }
};
