import { gql } from '@apollo/client';

const getCaregiverProfileQuery = gql`
  query getCaregiverProfile($caregiverId: Int!) {
    getCaregiverProfile(caregiverId: $caregiverId) {
      id
      pinCode
      name
      firstname
      user {
        username
        preferredUsername
        role
      }
      departments {
        id
        name
        color
        selfLockingDelay
        enabled
      }
      job {
        description
        name
        acronym
        id
      }
      currentDepartment {
        id
        name
        color
        selfLockingDelay
      }
      hospital {
        id
      }
    }
  }
`;

export default getCaregiverProfileQuery;
