import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Paragraph, Title } from '../../../components/texts';
import { Colors } from '../../../styles/theme';

const ActivityDetails = () => {
  const detailsItem = (item: any) => {
    return (
      <View style={styles.detailsSubContainer} key={item.id}>
        <View>
          <Image source={require('../../../assets/images/profil.png')} style={{ width: 50, height: 50 }} />
        </View>
        <View>
          <Paragraph>{item.title}</Paragraph>
          <Paragraph grey>{item.date}</Paragraph>
        </View>
      </View>
    );
  };

  return (
    <View>
      <View style={styles.detailsContainer}>
        <Title h3>Bientôt disponible</Title>
      </View>
    </View>
  );
};

export default ActivityDetails;

const styles = StyleSheet.create({
  detailsContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 1,
    shadowRadius: 11.95,
    elevation: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  detailsSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
  },
  detailsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
