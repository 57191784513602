import graphClient from '../graphClient/graphClient';
import getFeaturesForCaregiverQuery from './query/getFeaturesForCaregiverQuery';

const getFeaturesForCaregiverGraph = async (): Promise<any> => {
  try {
    return await graphClient.query({
      query: getFeaturesForCaregiverQuery,
    });
  } catch (error) {
    console.log(error.graphQLErrors);
  }
};

export default getFeaturesForCaregiverGraph;
