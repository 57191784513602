import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { isSmallScreen } from '../../utils/helpers/isSmallScreen';
import { Colors } from '../../styles/theme';

interface IMainCard {
  children: React.ReactNode;
  stickToHeader?: boolean;
  backgroundColor?: string;
  noPadding?: boolean;
}

const MainCardContainer = ({ children, stickToHeader, backgroundColor, noPadding }: IMainCard) => {
  const mainCardContainerStyle: Array<any> = [Platform.OS === 'web' ? styles.containerWeb : styles.containerNative];

  if (stickToHeader) mainCardContainerStyle.push(styles.stickToHeader);
  if (backgroundColor) mainCardContainerStyle.push({ backgroundColor: backgroundColor });
  if (noPadding) mainCardContainerStyle.push({ paddingHorizontal: 0, paddingVertical: 0 });

  return <View style={mainCardContainerStyle}>{children}</View>;
};

export default MainCardContainer;

const styles = StyleSheet.create({
  containerNative: {
    overflow: 'hidden',
    paddingVertical: isSmallScreen() ? 2 : 10,
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: 120,
    alignSelf: 'center',
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    marginTop: 10,
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.28,
    shadowRadius: 11.95,
    elevation: 0,
    backgroundColor: Colors.WHITE,
  },
  containerWeb: {
    overflow: 'hidden',
    paddingVertical: isSmallScreen() ? 2 : 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    maxWidth: 400,
    height: 'auto',
    alignSelf: 'center',
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    marginTop: 10,
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.28,
    shadowRadius: 11.95,
    elevation: 0,
    backgroundColor: Colors.WHITE,
  },
  stickToHeader: {
    position: 'absolute',
    top: isSmallScreen() ? 80 : 110,
    alignSelf: 'center',
    width: '92%',
  },
});
