import React from 'react';
import {FlatList, Image, Platform, TouchableOpacity, View} from 'react-native';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import {useUserContext} from '../../../providers/UserProvider';
import {StatusEnum} from '../../../types/enums/StatusEnum';
import HistoryCard from '../../../components/cards/HistoryCard';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import SelectDateHistory from '../../../components/date/SelectDateHistory';
import {Title} from '../../../components/texts';
import {ROUTES} from '../../../router/Routes';
import {Container} from '../../../layouts';

const ServiceHistoryScreen = () => {
    const [history, setHistory] = React.useState([]);
    const [activeDate, setActiveDate] = React.useState(new Date());
    const userContext = useUserContext();
    const {navigate} = useNavigation();

    useFocusEffect(
        React.useCallback(() => {
            if (!userContext.userState.profile) return;
            userContext.getServiceHistory(activeDate).then((response) => {
                setHistory(
                    response.data.DepartmentHistory.filter(
                        (request: any) =>
                            request.status.toUpperCase() === StatusEnum.CANCELLED ||
                            request.status.toUpperCase() === StatusEnum.TERMINATED
                    )
                        .map((item: any) => item)
                        .sort((a: any, b: any) => new Date(a.datetime).getTime() < new Date(b.datetime).getTime())
                );
            });
        }, [userContext.userState?.profile?.currentDepartment?.id, activeDate])
    );

    const renderData = ({item}: any) => {
        return (
            <TouchableOpacity onPress={() => navigate(ROUTES.REQUEST_DETAILS_SCREEN.name, {id: item.id})}>
                <HistoryCard
                    caregiver={item.caregiver ? item.caregiver : null}
                    category={item.name.fr}
                    date={item.take_at}
                    status={item.status}
                    position={item.position}
                    priority={item.priority}
                />
            </TouchableOpacity>
        );
    };

    const NoActivityIndicator = () => {
        return (
            <Container flexCol alignCenter justifyCenter marginVertical={50}>
                <Image source={require('../../../assets/images/requests/noRequest.png')}
                       style={{width: 70, height: 70}}/>
                <Title h2 center>
                    Aucune demande
                </Title>
                <Title h3 center>
                    Aucune activité enregistrée sur Hospitalink ce jour-ci
                </Title>
            </Container>
        );
    };

    return (
        <MainLayout serviceCard backButton>
            <ModuleContentView>
                <Container>
                    {
                        Platform.OS !== "web" && (
                            <Title h2 black>
                                Historique
                            </Title>
                        )

                    }

                    <SelectDateHistory date={activeDate} setActiveDate={setActiveDate}/>

                </Container>
                <View>
                    {history.length < 1 ? (
                        <NoActivityIndicator/>
                    ) : (
                        <FlatList data={history} renderItem={renderData} keyExtractor={(item: any) => item.id}/>
                    )}
                </View>
            </ModuleContentView>
        </MainLayout>
    );
};

export {ServiceHistoryScreen};
