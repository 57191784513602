import { Dimensions } from 'react-native';

export const isSmallScreen = () => {
  return Dimensions.get('screen').height < 751;
};


export const isMediumScreen = () => {

  return Dimensions.get('screen').width < 1100;

};
