import graphClient from '../graphClient/graphClient';
import { ISignIn } from '../../../types/authTypes';
import { FetchResult } from '@apollo/client';
import { signInMutation } from './query/signinMutation';

const signInGraph = async (SignInInput: ISignIn): Promise<FetchResult<any> | void> => {
  return await graphClient.mutate({ mutation: signInMutation, variables: { SignInInput } }).catch((error) => {
    console.log('gql error => ', error.graphQLErrors);
    throw new Error(error.graphQLErrors);
  });
};

export default signInGraph;
