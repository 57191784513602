import { gql } from '@apollo/client';

const getCaregiversFromDepartmentQuery = gql`
  query getCaregiversFromDepartment($departmentId: Float!) {
    getCaregiversFromDepartment(departmentId: $departmentId) {
      id
      pinCode
      name
      firstname
      departments {
        id
        name
        color
        selfLockingDelay
        enabled
      }
      job {
        description
        name
        acronym
      }
      currentDepartment {
        id
        name
        color
        selfLockingDelay
      }
      user {
        username
        role
        preferredUsername
      }
    }
  }
`;

export default getCaregiversFromDepartmentQuery;
