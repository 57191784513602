import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import { GRAPHQL_URL } from '../../../../env.json';
import { StorageService } from '../../../services/Storage.service';

const asyncStorage = new StorageService();

const graphMiddleware = setContext(async (_, { headers }) => {
  const { IdToken } = await asyncStorage.getTokens();
  return {
    headers: {
      ...headers,
      authorization: IdToken ? `${IdToken}` : '',
    },
  };
});

const graphLink = createHttpLink({
  uri: GRAPHQL_URL,
  fetch,
});

const graphInstance = graphMiddleware.concat(graphLink);

export default graphInstance;
