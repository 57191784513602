import React from 'react';
import { Container } from '../../layouts';
import { Title } from '../texts';
import { Octicons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';

const SelectDateHistory = ({ date, setActiveDate }: any) => {
  const currentDate = new Date(date);
  const yesterdayDate = new Date(date);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const tomorrowDate = new Date(date);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  let months = ['Jan.', 'Fev.', 'Mar.', 'Avr.', 'Mai', 'Juin', 'Jui.', 'Aou.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

  const renderDate = (date: any, active: boolean) => {
    return (
      <TouchableOpacity
        onPress={() => setActiveDate(new Date(date))}
        style={{
          flex: 1,
        }}
      >
        <Container
          flex={1}
          flexCol
          alignCenter
          justifyCenter
          primary={active}
          marginHorizontal={5}
          marginVertical={5}
          paddingVertical={10}
          height={70}
          radius={20}
        >
          <Title h1 mhXS mvXS white={active}>
            {date.getDate()}
          </Title>
          <Title h3 mhXS mvXS white={active}>
            {months[date.getMonth()]}
          </Title>
        </Container>
      </TouchableOpacity>
    );
  };

  return (
    <Container flexRow alignCenter justifyCenter flex={1} paddingHorizontal={10} marginVertical={10}>
      <Container>
        <TouchableOpacity onPress={() => setActiveDate(new Date(yesterdayDate))}>
          <Octicons name="chevron-left" size={24} color="gray" />
        </TouchableOpacity>
      </Container>
      <Container flexRow alignCenter justifyCenter>
        {renderDate(yesterdayDate, false)}
        {renderDate(currentDate, true)}
        {renderDate(tomorrowDate, false)}
      </Container>
      <Container>
        <TouchableOpacity onPress={() => setActiveDate(new Date(tomorrowDate))}>
          <Octicons name="chevron-right" size={24} color="gray" />
        </TouchableOpacity>
      </Container>
    </Container>
  );
};

export default SelectDateHistory;
