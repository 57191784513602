import { gql } from '@apollo/client';

const getMessagesOfDepartmentQuery = gql`
  query getMessageOfDepartment {
    getMessageOfDepartment {
      body
      createdAt
      id
      sendAt
      sendBy {
        id
        name
        user {
          preferredUsername
          username
        }
      }
      updatedAt
    }
  }
`;

export { getMessagesOfDepartmentQuery };
