import { gql } from '@apollo/client';

const finishDemandMutation = gql`
  mutation finishRequest($demandId: Int!) {
    finishDemand(demandId: $demandId) {
      status
    }
  }
`;
export default finishDemandMutation;
