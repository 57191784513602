import { gql } from '@apollo/client';

export const signUpMutation = gql`
  mutation signupCaregiver($SignUpInput: SignUpCaregiverInput!) {
    signupCaregiver(SignUpCaregiverInput: $SignUpInput) {
      id
      firstname
    }
  }
`;
