import { Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { useState } from 'react';

interface IGetWebviewOrLink {
  navigate: (url: string) => any;
  route: string;
  link: string;
  toggleModal?: () => void;
}

export const getWebviewOrLink = async ({ navigate, route, link, toggleModal }: IGetWebviewOrLink) => {
  if (Platform.OS === 'web') {
    await WebBrowser.openBrowserAsync(link);
  } else {
    navigate(route);
    if (toggleModal) toggleModal();
  }
};
