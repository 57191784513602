import actionObject from './actionObjects';
import { ActionPayloadType, ActionParameterDataType } from '../../types/actionTypes';

const userRequestsActions = {
  //objects (actions strings & payload)
  getServiceRequests: (payload: any) => actionObject.create('getServiceRequests', payload),
  updateServiceRequests: (payload: any) => actionObject.create('updateServiceRequests', payload),
  updateServiceRequest: (payload: any) => actionObject.create('updateServiceRequest', payload),

  //actions strings
  GET_SERVICE_REQUESTS: actionObject.create('getServiceRequests').type,
  UPDATE_SERVICE_REQUESTS: actionObject.create('updateServiceRequests').type,
  UPDATE_SERVICE_REQUEST: actionObject.create('updateServiceRequest').type,
  RESET_SERVICE_STATE: actionObject.create('resetServiceState').type,
};

export default userRequestsActions;
