import { ActionParameterDataType, ActionPayloadType } from '../../types/actionTypes';

const actionObject = {
  create: (actionName: string, payload?: ActionPayloadType): ActionParameterDataType => {
    return <ActionParameterDataType>{
      type: `ACTION_${actionName.toUpperCase()}`,
      payload: payload,
    };
  },
};

export default actionObject;
