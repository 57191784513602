import React from 'react';
import { Dimensions, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
// @ts-ignore
import Modal from 'modal-react-native-web';

import ServiceCard from '../cards/ServiceCard';
import { Title } from '../texts';
import { Colors } from '../../styles/theme';
import { useAuthContext } from '../../providers/AuthProvider';
import { ROUTES } from '../../router/Routes';
import { useNavigation } from '@react-navigation/native';
import ChangeServiceModal from './ChangeServiceModal';
import { amIAllowed } from '../../utils/helpers/amIAllowed';
import { useUserContext } from '../../providers/UserProvider';
import * as Linking from 'expo-linking';
import { RolesEnum } from '../../types/enums/RolesEnum';
import { getWebviewOrLink } from '../../utils/helpers/getWebviewOrLink';
import {isMediumScreen} from "../../utils/helpers/isSmallScreen";

interface ModalInterface {
  isModalVisible: boolean;
  toggleModal: () => void;
}

function NavModal({ isModalVisible, toggleModal }: ModalInterface) {
  const authContext = useAuthContext();
  const { navigate } = useNavigation();
  const userContext = useUserContext();

  return (
    <View style={{ flex: 1 }}>
      <Modal visible={isModalVisible} animationType="fade" transparent={true}>
        <TouchableWithoutFeedback onPress={() => toggleModal()}>
          <View style={styles.modal}>
            <ServiceCard toggleModal={toggleModal} isModalVisible={isModalVisible} />
            <View style={styles.menuContainer}>
              <View
                style={[
                  styles.arrow,
                  {
                    transform: [{ rotateZ: '45deg' }],
                  },
                ]}
              />
              <TouchableOpacity
                onPress={() => {
                  navigate(ROUTES.PROFILE_SCREEN.name);
                  toggleModal();
                }}
                style={{ width: '100%' }}
              >
                <Title h2 mvM>
                  Paramètres
                </Title>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  navigate(ROUTES.SERVICE_HISTORY_SCREEN.name);
                  toggleModal();
                }}
                style={{ width: '100%' }}
              >
                <Title h2 mvM>
                  Historique
                </Title>
              </TouchableOpacity>
              {amIAllowed(userContext.userState.profile?.user?.role.toUpperCase(), RolesEnum.MANAGER) ? (
              <TouchableOpacity
              onPress={() =>
                getWebviewOrLink({
                  navigate,
                  link: 'https://cdn.jotfor.ms/212154998902059',
                  route: ROUTES.ADDINFORMATION_SCREEN.name,
                  toggleModal
                })
              
              }>
                <Title h2 mvM>
                 Publier de l'information
                </Title>
            </TouchableOpacity>
            

                ) : null}

              <ChangeServiceModal />
              <TouchableOpacity onPress={() => authContext.logout()}>
                <Title h2 mvM secondary>
                  Se déconnecter
                </Title>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
}

export default NavModal;

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: Dimensions.get('screen').height,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  menuContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 15,
    padding: 20,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 500,
    position: 'absolute',
    top: isMediumScreen() ? 200 : 300,
  },
  arrow: {
    width: 30,
    height: 30,
    top: -15,
    right: 40,
    backgroundColor: Colors.WHITE,
    position: 'absolute',
  },
});
