import React from 'react';
import { View } from 'react-native';
import { Title } from '../texts';
import { IsContainNumber, IsContainSymbol, isContainUpper } from '../../utils/helpers/validations/passwordVerification';

interface IPasswordVerificationIndicator {
  password: string;
}

export const PasswordValidationIndicator = ({ password }: IPasswordVerificationIndicator) => {
  return (
    <View
      style={{
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Title h3 mvXS mhXS secondary={password.length < 8} primary={password.length >= 8}>
        8 Caractères minimum
      </Title>
      <Title h3 mvXS mhXS secondary={!isContainUpper(password)} primary={isContainUpper(password)}>
        1 Majuscule
      </Title>
      <Title h3 mvXS mhXS secondary={!IsContainNumber(password)} primary={IsContainNumber(password)}>
        1 Chiffre (0-9)
      </Title>
      <Title h3 mvXS mhXS secondary={!IsContainSymbol(password)} primary={IsContainSymbol(password)}>
        1 Caractères special (?!./:-...)
      </Title>
    </View>
  );
};
