import React from 'react';
import { Button } from '../../components/buttons';
import { InputText } from '../../components/inputs/InputText';
import { Paragraph, Title } from '../../components/texts';
import { Container } from '../../layouts/';
import { useNavigation } from '@react-navigation/native';
import { useAuthContext } from '../../providers/AuthProvider';
import { Image, TouchableOpacity } from 'react-native';
import ModuleContentView from '../../layouts/dashboard/ModuleContentView';
import MainLayout from '../../layouts/dashboard/MainLayout';
import { PasswordValidationIndicator } from '../../components/feedbacks/PasswordValidationIndicator';
import { ROUTES } from '../../router/Routes';
import { passwordConfirmationVerification, passwordVerification } from '../../utils/helpers/validations';
import { useSnackBarContext } from '../../providers/SnackBarProvider';
import { getWebviewOrLink } from '../../utils/helpers/getWebviewOrLink';

const ChangePasswordFirstConnexionScreen: React.FunctionComponent = () => {
  const authContext = useAuthContext();
  const snackBar = useSnackBarContext();
  const { navigate } = useNavigation();
  const [newPasswordPayload, setNewPasswordPayload] = React.useState({
    newPassword: '',
    newPasswordConf: '',
  });
  const [errors, setErrors] = React.useState({
    newPassword: false,
    newPasswordConf: false,
  });
  const [success, setSuccess] = React.useState({
    newPassword: false,
    newPasswordConf: false,
  });
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    if (
      passwordVerification(newPasswordPayload.newPassword) &&
      passwordConfirmationVerification(newPasswordPayload.newPassword, newPasswordPayload.newPasswordConf)
    ) {
      authContext.setNewPassword(newPasswordPayload);
      navigate(ROUTES.CHANGE_PINCODE_FIRST_LOGIN_SCREEN.name);
    } else {
      console.log('erreur');
      snackBar.displaySnackBar('Vérifiez votre nouveau mot de passe', 'DANGER');
    }
  };

  const handleNewPassword = (data: string) => {
    setNewPasswordPayload({
      ...newPasswordPayload,
      newPassword: data,
    });
  };

  const handleNewPasswordConf = (data: string) => {
    setNewPasswordPayload({
      ...newPasswordPayload,
      newPasswordConf: data,
    });
  };

  return (
    <MainLayout serviceCard>
      <Container primary flex={1}>
        <Container alignStart justifyCenter primary flex={1}>
          <Image source={require('../../assets/images/login.png')} style={{ width: '100%', height: '80%' }} />
        </Container>
      </Container>
      <ModuleContentView>
        <Title h2 black>
          🔑Nouveau mot de passe
        </Title>
        <Paragraph>
          En continuant l'inscription, vous acceptez également nos{' '}
          <TouchableOpacity
            onPress={() =>
              getWebviewOrLink({
                navigate,
                link: 'https://www.hospitalink.fr/soignant/faq',
                route: ROUTES.CONTACT_SCREEN.name,
              })
            }
          >
            <Paragraph primary>conditions générales d'utilisations</Paragraph>
          </TouchableOpacity>
        </Paragraph>

        <Container marginVertical={20}>
          {error ? (
            <Title h3 secondary>
              {error}
            </Title>
          ) : (
            <></>
          )}
          <InputText
            label="Nouveau mot de passe"
            password
            onChangeText={handleNewPassword}
            error={errors.newPassword}
            success={success.newPassword}
            filled={success.newPassword}
            value={newPasswordPayload.newPassword}
            noSpace
          />
          <InputText
            label="Confirmation de mot de passe"
            password
            onChangeText={handleNewPasswordConf}
            error={errors.newPasswordConf}
            success={success.newPasswordConf}
            filled={success.newPasswordConf}
            value={newPasswordPayload.newPasswordConf}
            noSpace
            onSubmitEditing={handleSubmit}
          />
          <PasswordValidationIndicator password={newPasswordPayload.newPassword} />
          <Button mvM label="Suivant" onPress={() => handleSubmit()} loading={loading} />
        </Container>
      </ModuleContentView>
    </MainLayout>
  );
};
export { ChangePasswordFirstConnexionScreen };
