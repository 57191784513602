import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import graphInstance from './graphInstance';
import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`, locations)
    );

  if (networkError) console.log('erreur :', networkError);
});

const graphClient = new ApolloClient({
  link: ApolloLink.from([errorLink, graphInstance]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default graphClient;
