import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Snackbar } from 'react-native-paper';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import { Colors } from '../../../styles/theme';

const MainSnackbar = () => {
  const { showSnackBar, hideSnackBar, snackBarContent, snackBarType, snackBarDuration } = useSnackBarContext();

  const snackBarStyle: Array<any> = [styles.snackBar];

  if (snackBarType === 'NEUTRAL') snackBarStyle.push(styles.snackBarNeutral);
  if (snackBarType === 'SUCCESS') snackBarStyle.push(styles.snackBarSuccess);
  if (snackBarType === 'ERROR') snackBarStyle.push(styles.snackBarError);

  return (
    <Snackbar
      visible={showSnackBar}
      onDismiss={hideSnackBar}
      duration={snackBarDuration}
      style={snackBarStyle}
      wrapperStyle={styles.snackBarContainer}
    >
      {snackBarContent}
    </Snackbar>
  );
};

const styles = StyleSheet.create({
  snackBarContainer: {
    position: 'absolute',
    top: 50,
    zIndex: 999,
  },
  snackBar: {
    padding: 10,
  },
  snackBarNeutral: {},
  snackBarSuccess: {
    backgroundColor: Colors.SUCCESS,
  },
  snackBarError: {
    backgroundColor: Colors.DANGER,
  },
});

export default MainSnackbar;
