import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Title, Paragraph } from '../../../components/texts';
import { Button } from '../../../components/buttons';
import { Colors } from '../../../styles/theme';
import { useNavigation, useRoute } from '@react-navigation/native';
import { usePositionsContext } from '../../../providers/PositionsProvider';
import { ROUTES } from '../../../router/Routes';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';

const ConfirmNewPatientScreen = (): React.ReactElement => {
  const positionsContext = usePositionsContext();
  const route = useRoute();
  const { positionRequestId } = route.params as any;
  const { navigate } = useNavigation();
  const [state, setState] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const { displaySnackBar } = useSnackBarContext();

  const acceptPosition = async (positionRequestId: number) => {
    setLoading(true);
    positionsContext.acceptPositionRequest(positionRequestId);
    setLoading(false);
    displaySnackBar('Position acceptée avec succès', 'SUCCESS');
    navigate(ROUTES.HOME_SCREEN.name);
  };

  React.useEffect(() => {
    const positionRequest = positionsContext.positionsState.positionsRequests.find(
      (request: any) => request.id === positionRequestId
    );
    setState(positionRequest);
  }, [positionRequestId]);


  return (
    <MainLayout backButton serviceCard>
      <ModuleContentView>
        <View style={styles.container}>
          <Title h1 primary mvXS>
            {state.patient?.name}
          </Title>
          <Title h3 black mvXS>
            souhaite se connecter en :
          </Title>
          <Title h1 primary mvXS>
            {state !== null ? `${state.position?.room?.name} - ${state.position?.name}` : 'Chambre'}
          </Title>

          <View style={styles.textContainer}>
            <Paragraph secondary>
              ⚠️ {state.position?.patient?.name} est actuellement connecté à cet emplacement. En acceptant{' '}
              {state.patient?.name} vous déconnecterez {state.position?.patient?.name}
            </Paragraph>
          </View>

          <View style={styles.buttonContainer}>
            <Button
              label="Confirmer"
              loading={loading}
              acceptrequest
              onPress={() => acceptPosition(positionRequestId)}
              success
            />
          </View>
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export { ConfirmNewPatientScreen };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginVertical: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    padding: 20,
    marginVertical: 10,
  },
  textDangerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#f3a9ba',
    borderRadius: 20,
    width: '100%',
    padding: 20,
    marginVertical: 10,
    borderColor: Colors.SECONDARY,
    borderWidth: 1,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
  },
});
