import authAction from '../actions/authActions';
import { ActionParameterDataType } from '../../types/actionTypes';
import { AuthStateType } from '../../types/stateTypes';
import jwt_decode from 'jwt-decode';
import authActions from '../actions/authActions';

export const initialAuthState: AuthStateType = {
  isConnected: false,
  isLoading: false,
  isInitialized: false,
  error: false,
  token: '',
  updateToken: '',
  tokenInfos: '',
  oldPassword: '',
  newPassword: '',
  oldPinCode: '',
  newPinCode: '',
};

export const authReducer = (state = initialAuthState, action: ActionParameterDataType): AuthStateType => {
  switch (action.type) {
    case authAction.LOGIN:
      return {
        ...state,
        isLoading: false,
        isConnected: true,
        isInitialized: true,
        token: action.payload.IdToken,
        updateToken: action.payload.RefreshToken,
        tokenInfos: jwt_decode(<string>action.payload.IdToken),
      };
    case authAction.REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload.IdToken,
        updateToken: action.payload.RefreshToken,
        tokenInfos: jwt_decode(<string>action.payload.IdToken),
      };
    case authAction.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case authAction.ERROR:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        isConnected: false,
        error: true,
      };
    case authAction.LOGOUT:
      return {
        ...initialAuthState,
        isInitialized: true,
        isLoading: false,
      };
    case authAction.SET_NEW_PIN_CODE:
      return {
        ...state,
        oldPinCode: action.payload.oldPinCode,
        newPinCode: action.payload.newPinCode,
      };
    case authAction.SET_NEW_PASSWORD:
      return {
        ...state,
        oldPassword: action.payload.oldPassword,
        newPassword: action.payload.newPassword,
      };
    default:
      return state;
  }
};
