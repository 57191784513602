import getServiceDemandsGraph from './getServiceDemands';
import getDemandByIdGraph from './getDemandById';
import takeDemandGraph from './takeDemand';
import finishDemandGraph from './finishDemandGraph';

export default {
  getServiceDemands: getServiceDemandsGraph,
  getDemandById: getDemandByIdGraph,
  takeDemand: takeDemandGraph,
  finishDemand: finishDemandGraph,
};
