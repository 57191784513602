import React, {useState} from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Title } from '../../../components/texts';
import { Radius } from '../../../styles/theme';
import { MaterialIcons } from '@expo/vector-icons';
import { ROUTES } from '../../../router/Routes';
import { useNavigation } from '@react-navigation/native';
import { usePositionsContext } from '../../../providers/PositionsProvider';
import { NoActivityIndicator } from '../../../components/feedbacks/NoActivityIndicator';
import { Container } from '../../../layouts';
import {useUserContext} from "../../../providers/UserProvider";
import FreeBedModal from "../../../components/modals/FreeBedModal";

const Item = ({ title, occupedBy, id, setCurrentPosition, setModalVisible }: any) => {
  return (
    <TouchableOpacity onPress={() => {
      setCurrentPosition(id);
      setModalVisible(true)

    }}>
      <View style={styles.itemContainer}>
        <Container flexCol alignStart justifyStart>
          <Container alignCenter justifyStart flexRow>
            <Title h3 mvXS mhS>
              {title}
            </Title>
          </Container>
          <Container alignCenter justifyStart flexRow>
            <Title h3 primary mvXS mhS>
              {occupedBy}
            </Title>
          </Container>
        </Container>
        <Container alignEnd flex={1}>
          <MaterialIcons name="logout" size={24} color="red" />
        </Container>
      </View>
    </TouchableOpacity>
  );
};

const BedsOverview = () => {
  const positionsContext = usePositionsContext();
  const [currentPosition, setCurrentPosition] = React.useState<any>(null);
  const [isModalVisible, setModalVisible] = useState(false);

  React.useEffect(() => {
    positionsContext.getDepartmentPositions();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      positionsContext.getDepartmentPositions();
    }, 5000)

    return () => clearInterval(interval);
  }, []);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const renderItem = (item: any) => {
    return (
      <Item
          setModalVisible={setModalVisible}
          setCurrentPosition={setCurrentPosition} title={`${item.room?.name} - ${item.name}`} occupedBy={item.patient?.name} key={item.id} id={item.id} />
    );
  };

  return (
      <>
    <View>
      <Title h2 mvS>
        Lits occupés : {positionsContext.positionsState.positions.filter((item: any) => item.occupied === true).length}/
        {positionsContext.positionsState.positions.length}
      </Title>
      <View>
        {positionsContext.positionsState.positions.filter((item: any) => item.occupied === true).length === 0 ? (
          <NoActivityIndicator />
        ) : (
          <></>
        )}
        {positionsContext.positionsState.positions
          .filter((item: any) => item.occupied === true)
          .map((item: any) => renderItem(item))}
      </View>
    </View>
        <FreeBedModal isModalVisible={isModalVisible} toggleModal={toggleModal} positionId={currentPosition}/>

      </>
  );
};

export default BedsOverview;

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: '#ffffff',
    borderRadius: Radius.SMALL,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 20,
    marginTop: 10,
  },
});
