import { gql } from '@apollo/client';

const getDepartmentPasswordQuery = gql`
  query getDepartmentPassword {
    getDepartmentPassword {
      password
    }
  }
`;

export { getDepartmentPasswordQuery };
