import React from 'react';

import { FetchResult } from '@apollo/client';
import graphAPI from '../api/graphAPI';

import { injectable } from 'inversify';
import 'reflect-metadata';

@injectable()
export class FeaturesService {
  constructor(private readonly featuresDisptach: React.Dispatch<any>) {}

  async getFeaturesEnabled(): Promise<FetchResult<any> | void | boolean> {
    return await graphAPI.features.getFeaturesForCaregiver();
  }
}
