export const BUTTON_LARGE = {
  height: 65,
  width: '100%',
};
export const BUTTON_MEDIUM = {
  height: 60,
  width: '70%',
};
export const BUTTON_ACCEPTREQUEST = {
  height: 65,
  width: '100%',
  margin: 8,
};
export const BUTTON_SMALL = {
  height: 35,
  width: 'auto',
};

export const BUTTON_FLEX_1 = {
  height: 45,
  flex: 1,
};
