import React from 'react';
import { StyleProp, StyleSheet, Text } from 'react-native';
import { Colors } from '../../styles/theme/';

interface InputLabelProps {
  children: string;
  error?: boolean;
  filled?: boolean;
}

export const InputLabel: React.FunctionComponent<InputLabelProps> = ({ children, error, filled }: InputLabelProps) => {
  const InputLabelStyles: Array<StyleProp<any>> = [styles.label];

  if (error) InputLabelStyles.push(styles.error);
  if (filled) InputLabelStyles.push(styles.filled);

  return <Text style={InputLabelStyles}>{children}</Text>;
};

const styles = StyleSheet.create({
  label: {
    color: Colors.GREY,
    fontWeight: '500',
    fontSize: 17,
    width: 'auto',
    position: 'absolute',
    top: -12,
    left: 50,
    backgroundColor: 'white',
  },
  error: {
    color: Colors.DANGER,
  },
  filled: {
    color: Colors.PRIMARY,
  },
});
