import graphClient from '../graphClient/graphClient';

import finishDemandMutation from './graphDemandsQueries/finishDemandMutation';

const finishDemandGraph = async (demandId: number) => {
  return await graphClient.mutate({
    mutation: finishDemandMutation,
    variables: { demandId },
  });
};

export default finishDemandGraph;
