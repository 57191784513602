import graphClient from '../graphClient/graphClient';

import addDeviceToUserMutation from './graphUserQueries/addDeviceToUserMutation';

type DeviceInput = {
  deviceId?: string;
  expoToken: string;
  experienceId?: string;
};

const addDeviceToUserGraph = async ({ expoToken, experienceId }: DeviceInput) => {
  return await graphClient.mutate({
    mutation: addDeviceToUserMutation,
    variables: { deviceInput: { expoToken, experienceId } },
  });
};

export default addDeviceToUserGraph;
