import { gql } from '@apollo/client';

export const getAllPositionsQueries = gql`
  query getAllPositions($filter: FilterPositionsInput!) {
    getAllPositions(filterPositionsInput: $filter) {
      id
      createdAt
      updatedAt
      enabled
      name
      patient {
        name
      }
      room {
        name
      }
      uniqueId
      occupied
    }
  }
`;
