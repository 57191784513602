import graphClient from '../graphClient/graphClient';
import { FetchResult } from '@apollo/client';
import { refreshTokenMutation } from './query/refreshTokenMutation';

interface IRefreshToken {
  RefreshToken: string;
}

export const refreshTokenGraph = async (RefreshTokenInput: IRefreshToken): Promise<FetchResult<any> | void> => {
  return await graphClient
    .mutate({ mutation: refreshTokenMutation, variables: { RefreshTokenInput } })
    .catch((error) => {
      console.log('gql error => ', error.graphQLErrors);
    });
};
