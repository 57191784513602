import asyncStorage from '@react-native-async-storage/async-storage';
import { injectable } from 'inversify';
import 'reflect-metadata';

@injectable()
export class StorageService {
  async setTokens({ access, refresh, id }: { access?: string; refresh?: string; id?: string }): Promise<void> {
    if (access) await asyncStorage.setItem('AccessToken', access);
    if (refresh) await asyncStorage.setItem('RefreshToken', refresh);
    if (id) await asyncStorage.setItem('IdToken', id);
  }

  async getTokens(): Promise<Record<string, any>> {
    const AccessToken = await asyncStorage.getItem('AccessToken');
    const RefreshToken = await asyncStorage.getItem('RefreshToken');
    const IdToken = await asyncStorage.getItem('IdToken');

    return { AccessToken, RefreshToken, IdToken };
  }

  async removeRtoTokens(): Promise<void> {
    await asyncStorage.clear();
  }

  async setIdOfLastMessageSeen(id: number): Promise<void> {
    if (id) await asyncStorage.setItem('lastMessage', id.toString());
  }

  async getIdOfLastMessageSeen(): Promise<string | null> {
    return await asyncStorage.getItem('lastMessage');
  }
}
