import { gql } from '@apollo/client';

export const getPositionRequestsOfDepartment = gql`
  query getPositionRequestsOfDepartment($departmentId: Int!) {
    getPositionRequestsOfDepartment(departmentId: $departmentId) {
      id
      createdAt
      updatedAt
      occupied
      patient {
        id
        name
        state
      }
      position {
        id
        name
        occupied
        enabled
        room {
          name
        }
        patient {
          name
          firstname
        }
      }
    }
  }
`;
