import featuresActions from '../actions/featuresAction';
import { ActionParameterDataType } from '../../types/actionTypes';

interface FeaturesStateType {
  communication: boolean;
  hospiteam: boolean;
  information: boolean;
  isInitialized: boolean;
}

export const initialFeaturesState: FeaturesStateType = {
  communication: false,
  hospiteam: false,
  information: false,
  isInitialized: false,
};

export const featuresReducer = (state = initialFeaturesState, action: ActionParameterDataType): FeaturesStateType => {
  switch (action.type) {
    case featuresActions.SET_FEATURES:
      return { ...state, ...action.payload.newFeaturesState };
    default:
      return state;
  }
};
