import React from 'react';
import { ROUTES } from './Routes';
import { NavigationContainer } from '@react-navigation/native';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import { enableScreens } from 'react-native-screens';
import { LoginScreen } from '../screens/auth/Login.screen';
import { PinCodeScreen } from '../screens/auth/PinCode.screen';
import TabNavigator from './TabNavigator';
import { DetailsRequestScreen } from '../screens/dashboard/requests/DetailsRequestScreen';
import { NewPatientScreen } from '../screens/dashboard/requests/NewPatientScreen';
import { AddCaregiverScreen } from '../screens/dashboard/caregivers/AddCaregiverScreen';
import { UpdateCaregiverScreen } from '../screens/dashboard/caregivers/UpdateCaregiverScreen';
import { LeaveBedScreen } from '../screens/dashboard/caregivers/LeaveBedScreen';
import { useAuthContext } from '../providers/AuthProvider';
import * as SplashScreen from 'expo-splash-screen';
import { MyCaregiverProfileScreen } from '../screens/dashboard/caregivers/MyCaregiverProfileScreen';
import { ContactScreen } from '../screens/dashboard/legal/ContactScreen';
import { ConditionGeneraleScreen } from '../screens/dashboard/legal/ConditionGeneraleScreen';
import { PolitiqueScreen } from '../screens/dashboard/legal/PolitiqueScreen';
import UpdateMyPseudoScreen from '../screens/dashboard/caregivers/UpdateMyPseudoScreen';
import { useAppStateContext } from '../providers/AppstateProvider';
import { ServiceHistoryScreen } from '../screens/dashboard/caregivers/ServiceHistoryScreen';
import UpdateMyCodeScreen from '../screens/dashboard/caregivers/UpdateMyCodeScreen';
import { ChangePasswordScreen } from '../screens/auth/ChangePassword.screen';
import { useUserContext } from '../providers/UserProvider';
import { PasswordStatusEnum } from '../types/enums/PasswordStatusEnum';
import { ChangePasswordFirstConnexionScreen } from '../screens/auth/ChangePasswordFirstConnexion.screen';
import { ChangePinCodeFirstConnexionScreen } from '../screens/auth/ChangePinCodeFirstConnexion.screen';
import { useChatContext } from '../providers/ChatProvider';
import { IChatMessage } from '../types/hospiTeamTypes';
import { useFeaturesContext } from '../providers/FeaturesProvider';
import { AddInformationScreen } from '../screens/dashboard/AddInformationScreen';
import { DashboardWeb } from '../screens/auth/DashboardWeb.screen';
import { Platform } from 'react-native';
import { ConfirmNewPatientScreen } from '../screens/dashboard/requests/ConfirmNewPatientScreen';

enableScreens();
const Stack = createSharedElementStackNavigator();

const authorizedStack = (
  <>
    <Stack.Screen name={ROUTES.PIN_SCREEN.name} component={Platform.OS === 'web' ? DashboardWeb : PinCodeScreen} />
    <Stack.Screen name={ROUTES.PIN_SCREEN_WEB.name} component={PinCodeScreen} />
    <Stack.Screen
      name={ROUTES.HOME_SCREEN.name}
      component={TabNavigator}
      options={{
        gestureEnabled: false,
      }}
    />
    <Stack.Screen name={ROUTES.REQUEST_DETAILS_SCREEN.name} component={DetailsRequestScreen} />
    <Stack.Screen name={ROUTES.NEW_PATIENT_SCREEN.name} component={NewPatientScreen} />
    <Stack.Screen name={ROUTES.CONFIRM_NEW_PATIENT_SCREEN.name} component={ConfirmNewPatientScreen} />
    <Stack.Screen name={ROUTES.ADD_CAREGIVER_SCREEN.name} component={AddCaregiverScreen} />
    <Stack.Screen name={ROUTES.UPDATE_CAREGIVER_SCREEN.name} component={UpdateCaregiverScreen} />
    <Stack.Screen name={ROUTES.UPDATE_MY_PSEUDO_SCREEN.name} component={UpdateMyPseudoScreen} />
    <Stack.Screen name={ROUTES.UPDATE_MY_PIN_CODE.name} component={UpdateMyCodeScreen} />
    <Stack.Screen name={ROUTES.LEAVE_BED_SCREEN.name} component={LeaveBedScreen} />
    <Stack.Screen name={ROUTES.PROFILE_SCREEN.name} component={MyCaregiverProfileScreen} />
    <Stack.Screen name={ROUTES.CONTACT_SCREEN.name} component={ContactScreen} />
    <Stack.Screen name={ROUTES.ADDINFORMATION_SCREEN.name} component={AddInformationScreen} />
    <Stack.Screen name={ROUTES.CONDITION_GENERAL_SCREEN.name} component={ConditionGeneraleScreen} />
    <Stack.Screen name={ROUTES.CGU_SCREEN.name} component={PolitiqueScreen} />
    <Stack.Screen name={ROUTES.SERVICE_HISTORY_SCREEN.name} component={ServiceHistoryScreen} />
    <Stack.Screen name={ROUTES.CHANGE_PASSWORD_SCREEN.name} component={ChangePasswordScreen} />
    <Stack.Screen
      name={ROUTES.CHANGE_CREDENTIALS_FIRST_LOGIN_SCREEN.name}
      component={ChangePasswordFirstConnexionScreen}
      options={{
        gestureEnabled: false,
      }}
    />
    <Stack.Screen
      name={ROUTES.CHANGE_PINCODE_FIRST_LOGIN_SCREEN.name}
      component={ChangePinCodeFirstConnexionScreen}
      options={{
        gestureEnabled: false,
      }}
    />
  </>
);

const unAuthorizedStack = (
  <>
    <Stack.Screen name={ROUTES.LOGIN_SCREEN.name} component={LoginScreen} />
    <Stack.Screen name={ROUTES.CONTACT_SCREEN.name} component={ContactScreen} />
  </>
);

const StackNavigator = () => {
  const authContext = useAuthContext();
  const appStateContext = useAppStateContext();
  const userContext = useUserContext();
  const navigationRef = React.useRef(null) as any;
  const chatContext = useChatContext();
  const featuresContext = useFeaturesContext();

  React.useEffect(() => {
    if (!authContext.authState.isConnected) return;
    featuresContext.getFeaturesEnabled().catch((e) => console.log(e));
  }, [authContext.authState.isConnected, appStateContext.appStateVisible]);

  React.useEffect(() => {
    SplashScreen.preventAutoHideAsync()
      .then(async () => {
        await authContext.loginWithTokens();
        featuresContext.getFeaturesEnabled().then((response) => {
          console.log(response);
        });
        chatContext.getPassword().then(() => {
          chatContext.getMessages(1);
        });
      })
      .catch((e) => {
        SplashScreen.hideAsync().then(() => {
          console.info(e);
          throw new Error(e);
        });
      })
      .finally(async () => {
        await SplashScreen.hideAsync();
      });
  }, []);

  React.useEffect(() => {
    authContext.loginWithTokens().then(() => {
      console.log('');
    });
  }, [appStateContext.appStateVisible]);

  React.useEffect(() => {
    if (chatContext.chatState.messages.length <= 0) return;
    const messages = [...chatContext.chatState.messages].sort((a: IChatMessage, b: IChatMessage) =>
      a.id > b.id ? 1 : -1
    );
    const lastId = chatContext.getLastIdMessage();

    for (const [index, message] of messages.entries()) {
      chatContext.getIdOfLastMessageSeen().then((response) => {
        if (!response) return;
        if (message._id === parseInt(response)) {
          if (parseInt(response) === lastId) {
            chatContext.setNumberOfNewMessages(0);
          } else {
            let numberToSubtract = 0;
            messages.splice(0, index);
            messages.forEach((msg) => {
              if (msg.user._id === userContext.userState.profile.id) {
                numberToSubtract++;
              }
            });
            const notificationNumber = messages.length - 1 - numberToSubtract;

            chatContext.setNumberOfNewMessages(notificationNumber <= 0 ? 0 : notificationNumber);
          }
        }
      });
    }
  }, [appStateContext.appStateVisible]);

  React.useEffect(() => {
    if (!userContext.userState.profile) return;
    if (
      authContext.authState.isConnected &&
      userContext.userState.profile.passwordState === PasswordStatusEnum.NEED_CHANGE
    ) {
      navigationRef.current?.navigate(ROUTES.CHANGE_CREDENTIALS_FIRST_LOGIN_SCREEN.name);
    } else if (
      authContext.authState.isConnected &&
      appStateContext.needToTapePinCode &&
      userContext.userState.profile.passwordState !== PasswordStatusEnum.NEED_CHANGE
    ) {
      navigationRef.current?.navigate(ROUTES.PIN_SCREEN.name);
    }
  }, [userContext.userState.profile, appStateContext.needToTapePinCode]);

  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName={authContext.authState.isConnected ? ROUTES.HOME_SCREEN.name : ROUTES.LOGIN_SCREEN.name}
      >
        {authContext.authState.isConnected && featuresContext.featuresState.isInitialized
          ? authorizedStack
          : unAuthorizedStack}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default StackNavigator;
