function comparer(otherArray: any) {
  return function (current: any) {
    return (
      otherArray.filter(function (other: any) {
        return other.id == current.id;
      }).length == 0
    );
  };
}

export const getDifferences = (a: any, b: any) => {
  const onlyInA = a.filter(comparer(b));
  const onlyInB = b.filter(comparer(a));

  return onlyInA.concat(onlyInB);
};
