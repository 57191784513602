import React from 'react';
import { Image, View } from 'react-native';
import { Title } from '../texts';

export const NoActivityIndicator = () => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 50,
      }}
    >
        <Image source={require('../../assets/images/requests/noRequest.png')} style={{ width: 70, height: 70 }} />
        <Title h1 center color={"#032E5C"}>
            Vous êtes à jour
        </Title>
        <Title h3 center color={"gray"} mvXS>
            Profitez de ce moment ☀️
        </Title>
    </View>
  );
};
