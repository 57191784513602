import graphClient from '../graphClient/graphClient';

import getServiceDemandsQuery from './graphDemandsQueries/getServiceDemandsQueries';

type FilterDemandInput = {
  departmentId: number;
  archived: boolean;
};

const getServiceDemandsGraph = async (filter: FilterDemandInput) => {
  return await graphClient.query({
    query: getServiceDemandsQuery,
    variables: { filter: { departmentId: filter.departmentId, archived: filter.archived } },
  });
};

export default getServiceDemandsGraph;
