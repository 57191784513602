import { gql } from '@apollo/client';

const postMessageMutation = gql`
  mutation postMessage($message: ChatMessageInput!) {
    postMessage(message: $message) {
      body
      createdAt
      id
      sendAt
      sendBy {
        name
        id
      }
      updatedAt
    }
  }
`;

export { postMessageMutation };
