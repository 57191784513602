import React from 'react';
import { Button } from '../../components/buttons';
import { InputText } from '../../components/inputs/InputText';
import { Title } from '../../components/texts';
import { Container } from '../../layouts/';
import { useNavigation } from '@react-navigation/native';
import { useAuthContext } from '../../providers/AuthProvider';
import { Image } from 'react-native';
import ModuleContentView from '../../layouts/dashboard/ModuleContentView';
import MainLayout from '../../layouts/dashboard/MainLayout';
import { ROUTES } from '../../router/Routes';
import { useSnackBarContext } from '../../providers/SnackBarProvider';
import { useUserContext } from '../../providers/UserProvider';

export const ChangePinCodeFirstConnexionScreen: React.FunctionComponent = () => {
  const snackbarContext = useSnackBarContext();
  const authContext = useAuthContext();
  const userContext = useUserContext();
  const { navigate } = useNavigation();
  const [newPinCodePayload, setNewPinCodePayload] = React.useState({
    newPinCode: '',
    newPinCodeConf: '',
  });
  const [errors, setErrors] = React.useState({
    newPinCode: false,
    newPinCodeConf: false,
  });
  const [success, setSuccess] = React.useState({
    newPinCode: false,
    newPinCodeConf: false,
  });
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    if (
      newPinCodePayload.newPinCode.length === 4 &&
      newPinCodePayload.newPinCode === newPinCodePayload.newPinCodeConf
    ) {
      setLoading(true);
      await authContext.setNewPinCode(newPinCodePayload);
      const response = await authContext.setNewCaregiverCredentials(newPinCodePayload.newPinCode);
      if (response) {
        setLoading(false);
        await userContext.getCaregiverProfile();
        snackbarContext.displaySnackBar('Modification enregistrée', 'SUCCESS');
        navigate(ROUTES.HOME_SCREEN.name);
      } else {
        setLoading(false);

        snackbarContext.displaySnackBar('Vérifiez votre code pin et mot de passe', 'ERROR');
      }
    } else {
      snackbarContext.displaySnackBar('Vérifiez votre code pin', 'ERROR');
    }
  };

  const handleNewPinCode = (data: string) => {
    if (data.toString().length > 4) return;
    setNewPinCodePayload({
      ...newPinCodePayload,
      newPinCode: data.replace(/\D/g, ''),
    });
  };

  const handleNewPinCodeConf = (data: string) => {
    if (data.toString().length > 4) return;
    setNewPinCodePayload({
      ...newPinCodePayload,
      newPinCodeConf: data.replace(/\D/g, ''),
    });
  };

  return (
    <MainLayout serviceCard backButton>
      <Container primary flex={1}>
        <Container alignStart justifyCenter primary flex={1}>
          <Image source={require('../../assets/images/login.png')} style={{ width: '100%', height: '80%' }} />
        </Container>
      </Container>
      <ModuleContentView>
        <Title h2 black>
          🔑Nouveau code PIN
        </Title>
        <Container marginVertical={20}>
          {error ? (
            <Title h3 secondary>
              {error}
            </Title>
          ) : (
            <></>
          )}
          <InputText
            label="Nouveau code"
            password
            onChangeText={handleNewPinCode}
            error={errors.newPinCode}
            success={success.newPinCode}
            filled={success.newPinCode}
            value={newPinCodePayload.newPinCode}
            noSpace
            numeric
          />
          <InputText
            label="Retapez votre nouveau code pin"
            password
            onChangeText={handleNewPinCodeConf}
            error={errors.newPinCodeConf}
            success={success.newPinCodeConf}
            filled={success.newPinCodeConf}
            value={newPinCodePayload.newPinCodeConf}
            noSpace
            numeric
            onSubmitEditing={handleSubmit}
          />
          <Button mvM label="Valider" onPress={() => handleSubmit()} loading={loading} />
        </Container>
      </ModuleContentView>
    </MainLayout>
  );
};
