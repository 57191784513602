//@ts-nocheck

import { injectable } from 'inversify';
import 'reflect-metadata';
import { FetchResult } from '@apollo/client';
import { AES, enc } from 'crypto-js';
import React from 'react';
import graphAPI from '../api/graphAPI';
import { IChatMessage, IMessage } from '../types/hospiTeamTypes';
import chatActions from '../providers/actions/chatActions';
import { StorageService } from './Storage.service';
import { container } from '../inversify.config';
import { TYPES } from '../DI/TYPES';

@injectable()
export class ChatService {
  private password: string | undefined;
  private asyncStorage: StorageService;

  constructor(private readonly chatDispatch: React.Dispatch<any>) {
    this.asyncStorage = container.get<StorageService>(TYPES.storageService);
  }

  async getPassword(): Promise<FetchResult<any> | void> {
    const password = await graphAPI.chat.getPassword();
    this.password = password.data.getDepartmentPassword.password;
    this.chatDispatch(chatActions.storePassword({ password: password.data.getDepartmentPassword.password }));
    return password;
  }

  async encrypt(message: string) {
    try {
      return AES.encrypt(message, this.password).toString();
    } catch (e) {
      console.error(e);
    }
  }

  async decrypt(message: string) {
    try {
      return AES.decrypt(message, this.password).toString(enc.Utf8);
    } catch (e) {
      console.error(e);
    }
  }

  async postMessage(message: IMessage): Promise<FetchResult<any> | void> {
    const encryptedMessage = await this.encrypt(message.body);
    return await graphAPI.chat.postMessage({ body: encryptedMessage });
  }

  async decryptMessages(messages: IMessage[]): Promise<FetchResult<any | void>> {
    const messagesDecrypted = [];
    for (const message of messages) {
      const msg = await this.decrypt(message.text);
      if (msg) {
        messagesDecrypted.push({
          _id: message._id,
          text: msg,
          createdAt: message.createdAt,
          user: {
            _id: message.user._id,
            name: message.user.name,
          },
        });
      }
    }

    return messagesDecrypted;
  }

  async getMessagesOfDepartment(page: number): Promise<FetchResult<any> | void> {
    await this.getPassword();
    const messages = await graphAPI.chat.getUserMessages({ page });
    const messagesDecrypted = await this.decryptMessages(messages.data.getMessageOfUserDepartment);
    this.chatDispatch(chatActions.setMessages({ messagesDecrypted }));
  }

  async getOldMessagesOfDepartment(page: number): Promise<FetchResult<any> | void> {
    await this.getPassword();
    const messages = await graphAPI.chat.getUserMessages({ page });
    const messagesDecrypted = await this.decryptMessages(messages.data.getMessageOfUserDepartment);
    this.chatDispatch(chatActions.setOldMessages({ messagesDecrypted }));
  }

  async setNewMessage(message: any): Promise<FetchResult<any> | void> {
    this.chatDispatch(chatActions.setNewMessage({ message }));
  }

  getLastIdMessage(messages: Array<IChatMessage>): number {
    const orderedMessage = messages.sort((a: IChatMessage, b: IChatMessage) => (a.id > b.id ? 1 : -1));
    if (orderedMessage.length > 0) return orderedMessage[orderedMessage.length - 1]._id;
  }

  async setLastIdMessageToStorage(id: number) {
    await this.asyncStorage.setIdOfLastMessageSeen(id);
  }

  getIdOfLastMessageSeen() {
    return this.asyncStorage.getIdOfLastMessageSeen();
  }
}
