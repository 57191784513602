import React, { Context, createContext, useContext, useReducer } from 'react';
import { ISignIn, ISignUp, IUpdatePassword } from '../types/authTypes';
import { AuthStateType } from '../types/stateTypes';
import { authReducer, initialAuthState } from './reducers/authReducer';
import { AuthService } from '../services/Auth.service';
import authActions from './actions/authActions';
import graphAPI from '../api/graphAPI';

interface AuthContextElements {
  authState: AuthStateType;
  login: (loginPayload: ISignIn) => Promise<any>;
  signup: (signupPayload: ISignUp) => Promise<any>;
  loginWithTokens: () => Promise<void>;
  logout: () => Promise<void>;
  changePassword: (changePasswordPayload: IUpdatePassword) => Promise<any>;
  checkTokenValidity: any;
  setNewPassword: any;
  authDispatch: any;
  setNewCaregiverCredentials: any;
  setNewPinCode: any;
}

const AuthContext: Context<AuthContextElements> = createContext({} as AuthContextElements);

const AuthProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [authState, authDispatch]: any = useReducer(authReducer as any, initialAuthState);
  const authService = new AuthService(authDispatch);

  const login = (signInPayload: ISignIn) => authService.signIn(signInPayload);

  const signup = (signupPayload: ISignUp) => authService.signUp(signupPayload);

  const logout = async () => {
    await graphAPI.user.deleteUsersDevice();
    await authService.logout();
  };

  const checkTokenValidity = () => authService.checkIdTokenValidity();

  const refreshToken = () => authService.refreshToken();

  const changePassword = async (changePasswordPayload: IUpdatePassword) =>
    authService.updatePassword(changePasswordPayload);

  const loginWithTokens = () => authService.signInWithToken();

  const setNewCaregiverCredentials = async (pincode: string): Promise<any> => {
    return await authService.setCredentialsCaregiver({
      username: authState.tokenInfos['cognito:username'],
      password: authState.newPassword,
      pinCode: pincode,
    });
  };

  const setNewPassword = async (newPasswordPayload: any): Promise<any> => {
    authDispatch(authActions.setNewPassword(newPasswordPayload));
  };

  const setNewPinCode = async (newPinCodePayload: any): Promise<any> => {
    authDispatch(authActions.setNewPinCode(newPinCodePayload));
  };

  React.useEffect(() => {
    if (!authState.isConnected) return;

    const refreshTokenInterval = setInterval(async () => {
      await refreshToken();
    }, 600000);

    return () => clearInterval(refreshTokenInterval);
  }, [authState.isConnected]);

  return (
    <AuthContext.Provider
      value={{
        changePassword,
        authState,
        login,
        signup,
        logout,
        loginWithTokens,
        checkTokenValidity,
        setNewPassword,
        authDispatch,
        setNewCaregiverCredentials,
        setNewPinCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = (): AuthContextElements => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
