import { gql } from '@apollo/client';

const getMessagesOfUserDepartmentQuery = gql`
  query getMessagesOfUserDepartmentQuery($ChatMessageFilter: ChatMessageFilterInput!) {
    getMessageOfUserDepartment(ChatMessageFilter: $ChatMessageFilter) {
      _id
      createdAt
      text
      user {
        name
        _id
        avatar
      }
    }
  }
`;

export { getMessagesOfUserDepartmentQuery };
