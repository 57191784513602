import postMessageGraph from './postMessageGraph';
import getMessagesOfDepartmentGraph from './getMessagesOfDepartment';
import getMessagesOfUserDepartmentGraph from './getMessageOfUserDepartmentGraph';
import getDepartmentPasswordGraph from './getDepartmentPasswordGraph';

export default {
  postMessage: postMessageGraph,
  getMessages: getMessagesOfDepartmentGraph,
  getUserMessages: getMessagesOfUserDepartmentGraph,
  getPassword: getDepartmentPasswordGraph,
};
