import { gql } from '@apollo/client';

const takeDemandMutation = gql`
  mutation takeDemand($demandId: Int!) {
    takeDemand(demandId: $demandId) {
      status
    }
  }
`;
export default takeDemandMutation;
