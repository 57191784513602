import graphClient from '../graphClient/graphClient';

import takeDemandMutation from './graphDemandsQueries/takeDemandMutation';

const takeDemandGraph = async (demandId: number) => {
  return await graphClient.mutate({
    mutation: takeDemandMutation,
    variables: { demandId },
  });
};

export default takeDemandGraph;
