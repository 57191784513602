import React from 'react';
import { StyleProp, StyleSheet, View } from 'react-native';
import { Paragraph } from './Paragraph';

interface ILabel {
  children: string;
  color?: string;
}

export const Label: React.FunctionComponent<ILabel> = ({ children, color }: ILabel) => {
  const labelStyles: Array<StyleProp<any>> = [styles.label];

  if (color) labelStyles.push({ backgroundColor: color });

  return (
    <View style={labelStyles}>
      <Paragraph>{children}</Paragraph>
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    borderRadius: 10,
    padding: 5,
    backgroundColor: 'white',
  },
});