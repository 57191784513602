import graphClient from '../graphClient/graphClient';
import { IResetCredentialsCaregiverInput } from '../../../types/authTypes';
import { FetchResult } from '@apollo/client';
import { setCredentialsCaregiverMutation } from './query/setCredentialsCaregiverMutation';

export const setCredentialsCaregiverGraph = async (
  ResetCredentialsCaregiverInput: IResetCredentialsCaregiverInput
): Promise<FetchResult<any> | void> => {
  return await graphClient
    .mutate({
      mutation: setCredentialsCaregiverMutation,
      variables: { ResetCredentialsInput: ResetCredentialsCaregiverInput },
    })
    .catch((error) => {
      console.log('gql error => ', error.graphQLErrors);
    });
};
