import graphClient from '../graphClient/graphClient';
import changeDepartment from './graphUserQueries/changeCurrentDepartmentMutation';

const changeCurrentDepartmentGraph = async (departmentId: number) => {
  return await graphClient.mutate({
    mutation: changeDepartment,
    variables: { departmentId },
  });
};

export default changeCurrentDepartmentGraph;
