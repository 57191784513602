import { getAllPositionsGraph } from './getAllPositionsGraph';
import { getPositionRequestsOfDepartmentGraph } from './getPositionRequestsOfDepartmentGraph';
import releasePositionGraph from './releasePositionGraph';
import rejectPositionRequestGraph from './rejectPositionRequestGraph';
import acceptPositionRequestGraph from './acceptPositionRequestGraph';
import getPositionGraph from './getPositionGraph';

export default {
  getAllPositions: getAllPositionsGraph,
  getPosition: getPositionGraph,
  getPositionRequestsOfDepartment: getPositionRequestsOfDepartmentGraph,
  releasePosition: releasePositionGraph,
  acceptPositionRequest: acceptPositionRequestGraph,
  rejectPositionRequest: rejectPositionRequestGraph,
};
