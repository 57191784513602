import { ApolloQueryResult } from '@apollo/client';
import graphClient from '../graphClient/graphClient';

import { GetCaregiverProfileResponseType } from '../../../types/graphQlResponseTypes';
import getCaregiversFromDepartmentQuery from './graphCaregiversQueries/getCaregiversFromDepartmentQueries';

const getCaregiversFromDepartmentGraph = async (
  departmentId: number
): Promise<ApolloQueryResult<GetCaregiverProfileResponseType>> => {
  return await graphClient.query({
    query: getCaregiversFromDepartmentQuery,
    variables: { departmentId },
  });
};

export default getCaregiversFromDepartmentGraph;
