import React, { useReducer } from 'react';
import graphAPI from '../api/graphAPI';
import { useUserContext } from './UserProvider';
import { WithActionType } from '../types/actionTypes';
import { demandsReducer, initialUserDemandsState } from './reducers/demandsReducer';
import requestsActions from './actions/requestsActions';
import { useAppStateContext } from './AppstateProvider';

interface UserDemandsContextType {
  getMyServiceDemands: (departmentId?: number) => any;
  setServiceDemands: (demands: any) => any;
  getDemandById: (id: number) => any;
  takeDemand: (id: number) => any;
  demandsState: any;
  finishDemand: (id: number) => any;
  updateServiceDemand: (demands: any) => any;
}

const DemandsContext = React.createContext<UserDemandsContextType>({} as UserDemandsContextType);

const DemandsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [demandsState, demandsDispatch]: WithActionType = useReducer(demandsReducer as any, initialUserDemandsState);
  const appStateContext = useAppStateContext();
  const userContext = useUserContext();

  React.useEffect(() => {
    if (!userContext.userState.profile?.currentDepartment?.id) return;
    getMyServiceDemands();
  }, [userContext.userState.profile?.currentDepartment?.id, appStateContext.appStateVisible]);

  const getMyServiceDemands = async (departmentId?: number) => {
    try {
      if (userContext.userState.profile?.currentDepartment?.id) {
        const requests = await graphAPI.demands.getServiceDemands({
          departmentId: departmentId ? departmentId : userContext.userState.profile?.currentDepartment?.id,
          archived: false,
        });
        demandsDispatch(requestsActions.getServiceRequests(requests.data.getDemands));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setServiceDemands = async (demand: any) => {
    try {
      if (demand) {
        demandsDispatch(requestsActions.updateServiceRequests(demand));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateServiceDemand = async (demand: any) => {
    try {
      if (demand) {
        demandsDispatch(requestsActions.updateServiceRequest(demand));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDemandById = async (id: number) => {
    try {
      return await graphAPI.demands.getDemandById(id);
    } catch (error) {
      console.log(error);
    }
  };

  const takeDemand = async (demandId: number) => {
    try {
      return await graphAPI.demands.takeDemand(demandId);
    } catch (error) {
      console.log(error);
    }
  };

  const finishDemand = async (demandId: number) => {
    try {
      return await graphAPI.demands.finishDemand(demandId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DemandsContext.Provider
      value={{
        demandsState,
        getMyServiceDemands,
        getDemandById,
        takeDemand,
        finishDemand,
        setServiceDemands,
        updateServiceDemand,
      }}
    >
      {children}
    </DemandsContext.Provider>
  );
};

const useDemandsContext = (): UserDemandsContextType => {
  return React.useContext(DemandsContext);
};

export { DemandsProvider, useDemandsContext };
