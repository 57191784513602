import React from 'react';
import { WebView } from 'react-native-webview';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import { Dimensions, Platform, View } from 'react-native';
import { isSmallScreen } from '../../../utils/helpers/isSmallScreen';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

const ContactScreen: React.FunctionComponent = () => {
  const screenHeight = Dimensions.get('screen').height - (isSmallScreen() ? 140 : 190);
  React.useEffect(() => {
    Platform.OS === 'web' && WebBrowser.openBrowserAsync('https://www.hospitalink.fr/soignant/faq');
  }, []);

  return (
    <MainLayout backButton white title={'contact'}>
      <View>
        <WebView
          originWhitelist={['*']}
          source={{ uri: 'https://www.hospitalink.fr/soignant/faq' }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          startInLoadingState={true}
          style={{ width: '100%', height: screenHeight }}
        />
      </View>
    </MainLayout>
  );
};
export { ContactScreen };
