export const PRIMARY = '#02D0DA';
export const PRIMARY_DARK = '#00aeb6';
export const SECONDARY = '#E9126C';
export const SUCCESS = '#63C391';
export const DANGER = '#AD024A';
export const GREY = '#6d6d6d';
export const WHITE = '#FFFFFF';
export const BLACK = '#2b2b2b';
export const BEIGE = '#FFF9EC';
export const PHASE_COLOR = ['#02D0DA', '#7849c4', '#f1784d', '#65b534', '#8D9DAC'];
export const REQUEST_CARDS = {
  NEUTRE: {
    light: '#d7d7d7',
    dark: '#404040',
  },
  LOW: {
    light: '#ecfaff',
    dark: '#12c9e9',
  },
  NORMAL: {
    light: '#ecfaff',
    dark: '#12c9e9',
  },
  HIGH: {
    light: '#fff7ec',
    dark: '#e9a112',
  },
  EMERGENCY: {
    light: '#f6c0c0',
    dark: '#E91212',
  },
  NEW_PATIENT: {
    light: '#E4FFEB',
    dark: '#469574',
  },
};
