import React from 'react';
import RequestsListsModule from '../../modules/dashboard/requests/RequestsLists.module';
import MainLayout from '../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../layouts/dashboard/ModuleContentView';
import { useDemandsContext } from '../../providers/DemandsProvider';
import { usePositionsContext } from '../../providers/PositionsProvider';
import { StatusEnum } from '../../types/enums/StatusEnum';
import { Image, Platform } from 'react-native';
import { Title } from '../../components/texts';
import { useFocusEffect } from '@react-navigation/native';
import { useUserContext } from '../../providers/UserProvider';
import { Container } from '../../layouts';

const PatientScreen = (): React.ReactElement => {
  const demandsContext = useDemandsContext();
  const positionsContext = usePositionsContext();
  const userContext = useUserContext();

  useFocusEffect(
    React.useCallback(() => {
      demandsContext.getMyServiceDemands();
      positionsContext.getDepartmentRequestsPositions();
    }, [userContext.userState.profile?.currentDepartment?.id])
  );

  const renderRequestModule = () => {
    if (
      demandsContext.demandsState.demands.filter(
        (request: any) =>
          request.status.toUpperCase() !== StatusEnum.TERMINATED &&
          request.status.toUpperCase() !== StatusEnum.CANCELLED
      ).length === 0 &&
      positionsContext.positionsState.positionsRequests.length === 0
    ) {
      return <NoRequestIndicator />;
    } else {
      return <RequestsListsModule />;
    }
  };

  const NoRequestIndicator = () => {
    return (
      <Container flexCol alignCenter justifyCenter marginVertical={50}>
        <Image source={require('../../assets/images/requests/noRequest.png')} style={{ width: 70, height: 70 }} />
          <Title h1 center color={"#032E5C"}>
              Vous êtes à jour
          </Title>
          <Title h3 center color={"gray"} mvXS>
              Profitez de ce moment ☀️
          </Title>
      </Container>
    );
  };

  return (
    <MainLayout serviceCard lockButton={Platform.OS === 'web'}>
      <ModuleContentView>{renderRequestModule()}</ModuleContentView>
    </MainLayout>
  );
};

export { PatientScreen };
