import React from 'react';
import { WebView } from 'react-native-webview';
import MainLayout from '../../layouts/dashboard/MainLayout';
import { Dimensions, Platform, View } from 'react-native';
import { isSmallScreen } from '../../utils/helpers/isSmallScreen';
import * as WebBrowser from 'expo-web-browser';

const AddInformationScreen: React.FunctionComponent = () => {
  const screenHeight = Dimensions.get('screen').height - (isSmallScreen() ? 140 : 190);



  React.useEffect(() => {
    Platform.OS === 'web' && WebBrowser.openBrowserAsync('https://cdn.jotfor.ms/212154998902059');
  }, []);

  return (
    <MainLayout backButton white title={'Information'}>
      <View>
        <WebView
          originWhitelist={['*']}
          source={{ uri: 'https://cdn.jotfor.ms/212154998902059' }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          startInLoadingState={true}
          style={{ width: '100%', height: screenHeight }}
        />
      </View>
    </MainLayout>
  );
};
export { AddInformationScreen };
