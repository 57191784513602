import graphClient from '../graphClient/graphClient';

import { getDepartmentPasswordQuery } from './query/getPasswordQuery';

const getDepartmentPasswordGraph = async () => {
  return await graphClient.query({
    query: getDepartmentPasswordQuery,
  });
};

export default getDepartmentPasswordGraph;
