import { FetchResult } from '@apollo/client';
import graphClient from '../graphClient/graphClient';
import deleteUserCaregiverQuery from './graphCaregiversQueries/deleteUserCaregiverQuery';

const deleteUserCaregiverGraph = async (username: string): Promise<FetchResult> => {
  return await graphClient.mutate({
    mutation: deleteUserCaregiverQuery,
    variables: { DeleteUserInput: { username: username } },
  });
};

export default deleteUserCaregiverGraph;
