import caregiversActions from '../actions/caregiversActions';

import { ActionParameterDataType } from '../../types/actionTypes';

export const initialCaregiversState = {
  isLoading: true,
  caregivers: [],
  error: null,
};

export const caregiversReducer = (state = initialCaregiversState, action: ActionParameterDataType) => {
  switch (action.type) {
    case caregiversActions.GET_CAREGIVERS_FROM_DEPARTMENT:
      return {
        ...state,
        caregivers: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
