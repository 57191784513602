//@ts-nocheck

import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Title } from '../../../components/texts';
import { Colors } from '../../../styles/theme';
import { AntDesign } from '@expo/vector-icons';
import { ROUTES } from '../../../router/Routes';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useCaregiversContext } from '../../../providers/CaregiversProvider';
import { Avatar } from 'react-native-paper';
import { useUserContext } from '../../../providers/UserProvider';
import { getUsernameOrPreferredUsername } from '../../../utils/helpers/validations/getUsernameOrPreferredUsername';
import { Container } from '../../../layouts';
import { CaregiverLifiCard } from '../../../components/cards/CaregiverLifiCard';

const Item = ({ name, id, caregiver }: any) => {
  const { navigate } = useNavigation();
  return (
    <TouchableOpacity
      onPress={() =>
        navigate(ROUTES.UPDATE_CAREGIVER_SCREEN.name, {
          caregiver: caregiver,
        })
      }
      key={id}
    >
      <View style={styles.caregiverContainer}>
        <View>
          <Avatar.Text
            size={70}
            label={name[0].toUpperCase()}
            style={{ backgroundColor: Colors.PRIMARY }}
            color={'white'}
          />
        </View>
        <View style={{ flex: 1, marginLeft: 10 }}>
          <Title h2 mvXS>
            {name}
          </Title>
          <Title h3 mvXS>
            {caregiver.job?.name?.fr}
          </Title>
        </View>
        <View>
          <AntDesign name="right" size={24} color="lightgray" />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const CaregiversOverview = () => {
  const { navigate } = useNavigation();
  const caregiversContext = useCaregiversContext();
  const userContext = useUserContext();

  React.useEffect(() => {
    caregiversContext.getCaregiversFromDepartment();
  }, [userContext.userState?.profile?.currentDepartment?.id]);

  useFocusEffect(
    React.useCallback(() => {
      caregiversContext.getCaregiversFromDepartment();
    }, [userContext.userState?.profile?.currentDepartment?.id])
  );

  const renderItem = (caregiver: any) => {
    return (
      <CaregiverLifiCard
        name={getUsernameOrPreferredUsername(caregiver)}
        id={caregiver.id}
        key={caregiver.id}
        caregiver={caregiver}
        connected={Math.floor(Math.random() * 2)}
        lastPosition={'couloir'}
      />
    );
  };

  return (
    <Container>
      <TouchableOpacity>
        <Container alignCenter justifyBetween flexRow>
          <Title h2 mvS>
            Soignants : {caregiversContext.caregiversState.caregivers.length}
          </Title>
          <TouchableOpacity onPress={() => navigate(ROUTES.ADD_CAREGIVER_SCREEN.name)}>
            <AntDesign name="pluscircle" size={34} color={Colors.PRIMARY} />
          </TouchableOpacity>
        </Container>
      </TouchableOpacity>
      <View>{caregiversContext.caregiversState.caregivers.map((caregiver: any) => renderItem(caregiver))}</View>
    </Container>
  );
};

export default CaregiversOverview;

const styles = StyleSheet.create({
  caregiverContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 20,
    marginTop: 10,
  },
});
