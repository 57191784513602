//TODO trouver une solution pour le problem de type octicons
//@ts-nocheck

import { StyleSheet, View } from 'react-native';
import React from 'react';
import { Octicons } from '@expo/vector-icons';

const PinCodeIndicator = ({ code }: any) => {
  return (
    <View style={styles.container}>
      {[0, 1, 2, 3].map((item, key) => {
        if (code[item] >= 0) return <Octicons key={key} name="dot" size={34} color="white" style={styles.item} />;
        else return <Octicons key={key} name="dot" size={14} color="white" style={styles.item} />;
      })}
    </View>
  );
};

export { PinCodeIndicator };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 50,
  },
  item: {
    margin: 10,
  },
});
