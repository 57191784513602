import React from 'react';
//@ts-ignore
import Modal from 'modal-react-native-web';
import {Dimensions, StyleSheet, TouchableWithoutFeedback, View} from 'react-native';
import { Colors } from '../../styles/theme';
import {Paragraph, Title} from "../texts";
import {Button} from "../buttons";
import {usePositionsContext} from "../../providers/PositionsProvider";
import {useSnackBarContext} from "../../providers/SnackBarProvider";


interface ModalInterface {
  isModalVisible: boolean;
  toggleModal: () => void;
  positionId: any;
}

function FreeBedModal({ isModalVisible, toggleModal, positionId }: ModalInterface) {
  const [loading, setLoading] = React.useState(false);
  const [position, setPosition] = React.useState<any>({});
  const positionsContext = usePositionsContext();
  const { displaySnackBar } = useSnackBarContext();

  React.useEffect(() => {
    positionsContext.getPositionById(positionId).then((response: any) => {
      setPosition(response.data.getPosition);
    });
  }, [positionId]);

  const leaveBed = async () => {
    setLoading(true);
    try {
      const response = await positionsContext.releasePosition(positionId);
      setLoading(false);
      if (response) {
        displaySnackBar('Position libérée', 'SUCCESS');
        toggleModal();
      }
    } catch (error) {
      setLoading(false);
      displaySnackBar('Impossible de libérer la position', 'ERROR');
    }
  };

  return (
      <View style={{ flex: 1 }}>
        <Modal visible={isModalVisible} animationType="fade" transparent={true}>
          <TouchableWithoutFeedback onPress={() => toggleModal()}>
            <View style={styles.modal}>
              <View style={styles.menuContainer}>
                <View>
                  <Title h2 primary>
                    Souhaitez-vous libérer
                    {position ? ` ${position.room?.name} - ${position.name}` : <></>}
                    ?
                  </Title>
                  <View>
                    <Paragraph>
                      Le patient ne pourra plus intéragir avec vous via Hospitalink.
                   </Paragraph>
                  </View>
                  <View style={{marginTop:30}}>
                    <Button label="Oui" secondary onPress={leaveBed} loading={loading} />
                    <Button label="Non" disable onPress={toggleModal} loading={loading} />
                  </View>
                </View>

              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
  );
}

export default FreeBedModal;

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: Dimensions.get('screen').height,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  menuContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 15,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    position: 'absolute',
    top: 300,
  },
  arrow: {
    width: 30,
    height: 30,
    top: -15,
    right: 40,
    backgroundColor: Colors.WHITE,
    position: 'absolute',
  },
});
