import asyncStorage from '@react-native-async-storage/async-storage';

export const storePincodeToLocalStorages = async (pincode: string) => {
  try {
    const currentPinCode = await getPincode();
    if (!currentPinCode || currentPinCode !== pincode) {
      await asyncStorage.setItem('pincode', pincode);
    }
  } catch (error) {
    console.log('error :', error);
  }
};

export const getPincode = () => {
  return asyncStorage.getItem('pincode');
};
