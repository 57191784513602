import React from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Colors, Spacing } from '../../styles/theme';
import {isMediumScreen, isSmallScreen} from '../../utils/helpers/isSmallScreen';

interface AuthBottomContainerProps {
  primary?: boolean;
  secondary?: boolean;
  children?: Array<JSX.Element> | JSX.Element;
}

export const AuthBottomContainer: React.FunctionComponent<AuthBottomContainerProps> = ({
  primary,
  secondary,
  children,
}: AuthBottomContainerProps) => {
  const buttonStyles = [styles.container, styles.default];

  if (primary) buttonStyles.push(styles.primary);
  if (secondary) buttonStyles.push(styles.secondary);

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={buttonStyles}>
      <ScrollView style={{ width: '100%' }}>
        <View style={styles.childContainer}>{children}</View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const flexHeight = isSmallScreen() ? 2 : 2.5;
const getContainerWidth = ():string => {
  if(Platform.OS === 'web') {
    if(isMediumScreen()){
      return '80%';
    }
    else{
      return '50%';
    }
  }else{
    return '100%';
  }
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: flexHeight,
    justifyContent: 'center',
    alignItems: 'center',
    width: getContainerWidth(),
    margin: 'auto',
    borderTopRightRadius: 80,
    borderTopLeftRadius: Platform.OS === 'web' ? 80 : 0,
    padding: Platform.OS === 'web' ? 10 : 0,
  },
  childContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...Spacing.PADDING_VERTICAL_SMALL,
    ...Spacing.PADDING_HORIZONTAL_SMALL,
    paddingBottom: 50,
  },
  primary: {
    backgroundColor: Colors.PRIMARY,
  },
  secondary: {
    backgroundColor: Colors.SECONDARY,
  },
  default: {
    backgroundColor: Colors.WHITE,
  },
});
