import graphClient from '../graphClient/graphClient';
import deleteUsersDeviceQuery from './graphUserQueries/deleteDeviceUserMutation';

const deleteDeviceUserGraph = async () => {
  return await graphClient.mutate({
    mutation: deleteUsersDeviceQuery,
  });
};

export default deleteDeviceUserGraph;
