import { gql } from '@apollo/client';

export const signInMutation = gql`
  mutation signinCaregiver($SignInInput: SignInInput!) {
    signinCaregiver(SignInInput: $SignInInput) {
      AuthenticationResult {
        AccessToken
        IdToken
        ExpiresIn
        RefreshToken
      }
    }
  }
`;
