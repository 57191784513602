import graphClient from '../graphClient/graphClient';

import { getMessagesOfDepartmentQuery } from './query/getMessagesOfDepartmentQuery';

const getMessagesOfDepartmentGraph = async () => {
  return await graphClient.query({
    query: getMessagesOfDepartmentQuery,
  });
};

export default getMessagesOfDepartmentGraph;
