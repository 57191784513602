import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../styles/theme';
import ServiceCard from '../../components/cards/ServiceCard';
import {AntDesign, Entypo} from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { isSmallScreen } from '../../utils/helpers/isSmallScreen';
import { HeaderModuleInterface } from '../../types/headerTypes/HeaderModuleType';
import NavModal from '../../components/modals/NavModal';
import RequestCard from '../../components/cards/RequestCard';
import { Paragraph, Title } from '../../components/texts';
import { useAppStateContext } from '../../providers/AppstateProvider';
import {Container} from "../../layouts";

const HeaderModule = ({
  requestCardHeaderData,
  serviceCard,
  backButton,
  title,
  lockButton,
  serviceInfos,
  retracted,
}: HeaderModuleInterface) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const appState = useAppStateContext();

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const { goBack } = useNavigation();

  const renderRequestCard = (request: any) => {
    return (
      <RequestCard
        icon={request.category.icon ? request.category.icon : null}
        title={request.category.name ? request.category.name.fr : 'null'}
        date={request.createdAt}
        haveComment={request.comment}
        status={request.status}
        priority={request.priority}
        category={request.category}
        id={request.id}
        position={request.position ? request.position : 'null'}
        stickToHeader
      />
    );
  };

  const getHeaderContent = () => {
    if (requestCardHeaderData) return renderRequestCard(requestCardHeaderData);
    if (serviceCard) return <ServiceCard toggleModal={toggleModal} isModalVisible={isModalVisible} />;
  };

  return (
    <>
      <NavModal isModalVisible={isModalVisible} toggleModal={toggleModal} />
      <View
        style={[
          styles.container,
          {
            height: retracted ? 100 : isSmallScreen() ? 140 : 190,
          },
        ]}
      >
        {backButton ? (
          <View style={styles.backButtonContainer}>
            <TouchableOpacity onPress={() => goBack()}>
              <AntDesign name="arrowleft" size={32} color="white" />
            </TouchableOpacity>
          </View>
        ) : (
          <View />
        )}
        {title && (
          <>
            <Title h2 white>
              {title}
            </Title>
          </>
        )}
        {serviceInfos ? (
          <>
            <View>
              <Title h2 white>
                {serviceInfos.name}
              </Title>
              <Paragraph white>{serviceInfos.caregivers_number} soignants</Paragraph>
            </View>
            <View />
          </>
        ) : (
          <View />
        )}
        {lockButton ? (
          <View style={styles.backButtonContainer}>

            <Container flex={1} justifyCenter alignCenter>
              <TouchableOpacity onPress={() =>  appState.setNeedToTapePinCode(true)} style={{
                backgroundColor: '#E9126C',
                width: 70,
                height: 70,
                borderRadius: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Entypo name='lock' size={40} color='white'/>
              </TouchableOpacity>
            </Container>
          </View>
        ) : null}
      </View>
      {!isModalVisible ? getHeaderContent() : null}
    </>
  );
};

export default HeaderModule;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    top: 0,
    left: 0,

    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
    backgroundColor: Colors.PRIMARY,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backButtonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 5,
  },
});
