import { gql } from '@apollo/client';

const getJobListQuery = gql`
  query getJobList {
    getJobList {
      acronym
      id
      description
      name
    }
  }
`;

export default getJobListQuery;
