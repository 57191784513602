import { ActionParameterDataType } from '../../types/actionTypes';
import { ChatStateType } from '../../types/stateTypes';
import chatActions from '../actions/chatActions';

export const initialChatState: ChatStateType = {
  password: '',
  messages: [],
};

export const ChatReducer = (state = initialChatState, action: ActionParameterDataType): ChatStateType => {
  switch (action.type) {
    case chatActions.STORE_PASSWORD:
      return {
        ...state,
        password: action.payload.password,
      };
    case chatActions.SET_MESSAGES:
      const messagesSorted = [...action.payload.messagesDecrypted];
      return {
        ...state,
        messages: messagesSorted,
      };
    case chatActions.SET_OLD_MESSAGES:
      const oldMessagesSorted = [...action.payload.messagesDecrypted];
      return {
        ...state,
        messages: [...state.messages, ...oldMessagesSorted],
      };
    case chatActions.SET_NEW_MESSAGE:
      const newMessageSorted = [...action.payload.message, ...state.messages];
      return {
        ...state,
        messages: newMessageSorted,
      };
    default:
      return state;
  }
};
