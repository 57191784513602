import graphClient from '../graphClient/graphClient';

import releasePositionMutation from './graphPositionsQueries/releasePositionMutation';

const releasePositionGraph = async (positionId: string) => {
  return await graphClient.mutate({
    mutation: releasePositionMutation,
    variables: { positionId },
  });
};

export default releasePositionGraph;
