import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../styles/theme';

interface RequestsBadge {
  count: number;
}

const RequestsBadge = ({ count }: RequestsBadge) => {
  return (
    <View style={styles.requestBadge}>
      <Text style={styles.requestBadgeLabel}>{count}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  requestBadge: {
    backgroundColor: Colors.PRIMARY,
    height: 35,
    width: 35,
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  requestBadgeLabel: {
    fontSize: 18,
    color: Colors.WHITE,
    fontWeight: 'bold',
  },
});

export default RequestsBadge;
