import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { Colors } from '../../styles/theme/';

interface ButtonLabelProps {
  secondary?: boolean;
  outlined?: boolean;
  children: string;
  disable?: boolean;
}

export const ButtonLabel: React.FunctionComponent<ButtonLabelProps> = ({
  children,
  secondary,
  outlined,
  disable,
}: ButtonLabelProps) => {
  const buttonLabelStyles = [styles.buttonLabel, styles.buttonLabelWhite];

  if (outlined && secondary) buttonLabelStyles.push(styles.buttonLabelSecondary);
  if (outlined && !secondary) buttonLabelStyles.push(styles.buttonLabelPrimary);
  if (disable) buttonLabelStyles.push(styles.disable);
  return <Text style={buttonLabelStyles}>{children}</Text>;
};

const styles = StyleSheet.create({
  buttonLabel: {
    fontSize: 22,
    fontWeight: '500',
  },
  buttonLabelDefault: {
    color: Colors.BLACK,
  },
  buttonLabelWhite: {
    color: Colors.WHITE,
  },
  buttonLabelPrimary: {
    color: Colors.PRIMARY,
  },
  buttonLabelSecondary: {
    color: Colors.SECONDARY,
  },
  disable: {
    color: Colors.GREY,
  },
});
