import React from 'react';

import { ROUTES } from '../../router/Routes';

import { passwordVerification } from '../../utils/helpers/validations';
import { Button } from '../../components/buttons';
import { InputText } from '../../components/inputs/InputText';
import { Paragraph, Title } from '../../components/texts';
import { AuthBottomContainer, Container } from '../../layouts/';
import { useNavigation } from '@react-navigation/native';
import { useAuthContext } from '../../providers/AuthProvider';
import { Image, TouchableOpacity } from 'react-native';
import { useUserContext } from '../../providers/UserProvider';
import { getWebviewOrLink } from '../../utils/helpers/getWebviewOrLink';

const LoginScreen: React.FunctionComponent = () => {
  const authContext = useAuthContext();
  const userContext = useUserContext();

  const { navigate } = useNavigation();
  const [loginPayload, setLoginPayload] = React.useState({
    username: '',
    password: '',
  });
  const [errors, setErrors] = React.useState({
    username: false,
    password: false,
  });
  const [success, setSuccess] = React.useState({
    username: false,
    password: false,
  });
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleUsername = (value: string) => {
    setLoginPayload({ ...loginPayload, username: value.replace(/ /g, '') });
    setSuccess({ ...success, username: true });
  };

  const handlePassword = (value: string) => {
    setLoginPayload({ ...loginPayload, password: value.replace(/ /g, '') });
    if (passwordVerification(value)) setSuccess({ ...success, password: true });
  };

  const handleSubmit = async () => {
    setLoading(true);

    const isPasswordValid = passwordVerification(loginPayload.password)
      ? true
      : setErrors({ ...errors, password: true });

    if (isPasswordValid) {
      authContext
        .login(loginPayload)
        .then(async (response) => {
          setErrors({ username: false, password: false });
          setSuccess({ username: true, password: true });
          await navigateToNextScreen();
        })
        .catch(async (e) => {
          setErrors({ username: true, password: true });
          setSuccess({ username: false, password: false });
          setError('Identifiant ou mot de passe incorrect');
          throw new Error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrors({ username: false, password: true });
      setSuccess({ username: false, password: false });
      setError('Le format du mot de passe ne convient pas');
      setLoading(false);
    }
  };
  const navigateToNextScreen = async () => {
    await userContext.getPatientProfile();
  };

  return (
    <Container primary flex={1}>
      <Container primary flex={1}>
        <Container alignStart justifyCenter primary flex={1}>
          <Image
            source={require('../../assets/images/login.png')}
            style={{ width: '100%', height: '100%', margin: 'auto' }}
          />
        </Container>
      </Container>
      <AuthBottomContainer>
        <Title h2 primary>
          Connexion
        </Title>
        {error ? (
          <Title h3 secondary>
            {error}
          </Title>
        ) : (
          <></>
        )}
        <InputText
          label="Identifiant"
          onChangeText={handleUsername}
          error={errors.username}
          success={success.username}
          filled={success.username}
          value={loginPayload.username}
        />
        <InputText
          label="Mot de passe"
          password
          onChangeText={handlePassword}
          error={errors.password}
          success={success.password}
          filled={success.password}
          value={loginPayload.password}
          onSubmitEditing={handleSubmit}
        />
        <Button mvL label="Connexion" onPress={handleSubmit} loading={loading} />
        <TouchableOpacity
          onPress={() =>
            getWebviewOrLink({
              navigate,
              link: 'https://www.hospitalink.fr/soignant/faq',
              route: ROUTES.CONTACT_SCREEN.name,
            })
          }
        >
          <Paragraph primary>Centre d&apos;aide</Paragraph>
        </TouchableOpacity>
      </AuthBottomContainer>
    </Container>
  );
};
export { LoginScreen };
