import React from 'react';

import { AuthProvider } from './AuthProvider';
import { WebsocketProvider } from './WebsocketProvider';
import { NotificationsProvider } from './NotificationsProvider';
import { UserProvider } from './UserProvider';
import { DemandsProvider } from './DemandsProvider';
import { PositionsProvider } from './PositionsProvider';
import { CaregiversProvider } from './CaregiversProvider';
import { AppStateProvider } from './AppstateProvider';
import { SnackBarProvider } from './SnackBarProvider';
import { ApolloProvider } from '@apollo/client';
import graphClient from '../api/graphAPI/graphClient/graphClient';
import { ChatProvider } from './ChatProvider';
import { FeaturesProvider } from './FeaturesProvider';

const MainProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <ApolloProvider client={graphClient}>
      <AppStateProvider>
        <SnackBarProvider>
          <AuthProvider>
            <FeaturesProvider>
              <UserProvider>
                <DemandsProvider>
                  <PositionsProvider>
                    <CaregiversProvider>
                      <NotificationsProvider>
                        <ChatProvider>
                          <WebsocketProvider>{children}</WebsocketProvider>
                        </ChatProvider>
                      </NotificationsProvider>
                    </CaregiversProvider>
                  </PositionsProvider>
                </DemandsProvider>
              </UserProvider>
            </FeaturesProvider>
          </AuthProvider>
        </SnackBarProvider>

        </AppStateProvider>
    </ApolloProvider>
  );
};

export default MainProvider;
