import graphClient from '../graphClient/graphClient';
import getServiceHistoryQuery from './graphUserQueries/getServiceHistoryQuery';

type FilterObjectInput = {
  startDate?: string;
  endDate?: string;
  take?: number;
  skip?: number;
};

export const getServiceHistoryGraph = async (date: Date, departmentId: number) => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  const tomorrowDate = new Date(date);
  tomorrowDate.setHours(23, 59, 59, 999);
  return graphClient.query({
    query: getServiceHistoryQuery,
    variables: {
      filters: { startDate: startDate, endDate: tomorrowDate },
      departmentId: departmentId,
    },
  });
};
