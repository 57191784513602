import React, { useReducer } from 'react';
import graphAPI from '../api/graphAPI';
import { WithActionType } from '../types/actionTypes';
import { initialPositionsState, positionsReducer } from './reducers/positionsReducer';
import positionsActions from './actions/positionsActions';
import { useUserContext } from './UserProvider';
import { useAppStateContext } from './AppstateProvider';
import { FetchResult } from '@apollo/client';

interface PositionsContextType {
  getDepartmentRequestsPositions: () => any;
  getDepartmentPositions: () => any;
  releasePosition: (positionId: string) => Promise<FetchResult<any> | undefined>;
  acceptPositionRequest: (positionsRequestId: number) => void;
  rejectPositionRequest: (positionsRequestId: number) => void;
  positionsState: any;
  setPositionRequest: (position: any) => void;
  getPositionById: (id: number) => any;
}

const PositionsContext = React.createContext<PositionsContextType>({} as PositionsContextType);

const PositionsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const userContext = useUserContext();
  const appStateContext = useAppStateContext();

  const [positionsState, positionsDispatch]: WithActionType = useReducer(
    positionsReducer as any,
    initialPositionsState
  );

  React.useEffect(() => {
    if (!userContext.userState.profile) return;
    getDepartmentRequestsPositions();
  }, [userContext.userState.profile?.currentDepartment?.id, appStateContext.appStateVisible]);

  React.useEffect(() => {
    if(!userContext.userState.profile) return;
    const interval = setInterval(() => {
      getDepartmentRequestsPositions();
    }, 5000)

    return () => clearInterval(interval);
  }, [userContext.userState.profile?.currentDepartment?.id, appStateContext.appStateVisible]);


  const getDepartmentRequestsPositions = async () => {
    try {
      if (userContext.userState.profile?.currentDepartment?.id) {
        const response = await graphAPI.positions.getPositionRequestsOfDepartment(
          userContext.userState.profile?.currentDepartment?.id
        );
        if (response)
          positionsDispatch(
            positionsActions.getDepartmentRequestPositions(response.data.getPositionRequestsOfDepartment)
          );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPositionRequest = (position: any) => {
    try {
      if (position) {
        positionsDispatch(positionsActions.updateDepartmentRequestPositions(position));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartmentPositions = async () => {
    try {
      if (userContext.userState.profile?.currentDepartment?.id) {
        const response = await graphAPI.positions.getAllPositions({
          departmentId: userContext.userState.profile?.currentDepartment?.id,
        });
        positionsDispatch(positionsActions.getDepartmentPositions(response.data.getAllPositions));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const releasePosition = async (positionId: string) => {
    try {
      const response = await graphAPI.positions.releasePosition(positionId);
      await getDepartmentPositions();
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const acceptPositionRequest = async (positionsRequestId: number) => {
    try {
      const response = await graphAPI.positions.acceptPositionRequest(positionsRequestId);
      if (response) await getDepartmentRequestsPositions();
    } catch (error) {
      console.log(error);
    }
  };

  const rejectPositionRequest = async (positionsRequestId: number) => {
    try {
      const response = await graphAPI.positions.rejectPositionRequest(positionsRequestId);
      if (response) await getDepartmentRequestsPositions();
    } catch (error) {
      console.log(error);
    }
  };

  const getPositionById = async (id: number) => {
    if (!id) return;
    try {
      return await graphAPI.positions.getPosition(id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PositionsContext.Provider
      value={{
        getDepartmentRequestsPositions,
        getDepartmentPositions,
        positionsState,
        releasePosition,
        acceptPositionRequest,
        rejectPositionRequest,
        setPositionRequest,
        getPositionById,
      }}
    >
      {children}
    </PositionsContext.Provider>
  );
};

const usePositionsContext = () => {
  return React.useContext(PositionsContext);
};

export { PositionsProvider, usePositionsContext };
