import positionsActions from '../actions/positionsActions';

import { ActionParameterDataType } from '../../types/actionTypes';

export const initialPositionsState = {
  isLoading: true,
  positionsRequests: [],
  positions: [],
  error: null,
};

export const positionsReducer = (state = initialPositionsState, action: ActionParameterDataType) => {
  switch (action.type) {
    case positionsActions.GET_DEPARTMENT_REQUEST_POSITIONS:
      return {
        ...state,
        positionsRequests: action.payload,
        isLoading: false,
      };
    case positionsActions.UPDATE_DEPARTMENT_REQUEST_POSITIONS:
      return {
        ...state,
        positionsRequests: [...state.positionsRequests, action.payload],
        isLoading: false,
      };
    case positionsActions.GET_DEPARTMENT_POSITIONS:
      return {
        ...state,
        positions: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
