import graphClient from '../graphClient/graphClient';

import { postMessageMutation } from './query/postMessageMutation';
import { IMessage } from '../../../types/hospiTeamTypes';

const postMessageGraph = async (message: IMessage) => {
  return await graphClient.mutate({
    mutation: postMessageMutation,
    variables: { message: message },
  });
};

export default postMessageGraph;
