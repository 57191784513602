import React from 'react';
import { StyleProp, StyleSheet, Text } from 'react-native';
import { Colors } from '../../styles/theme/';

interface PProps {
  secondary?: boolean;
  primary?: boolean;
  white?: boolean;
  grey?: boolean;
  children: any;
  center?: boolean;
}

export const Paragraph: React.FunctionComponent<PProps> = ({
  children,
  secondary,
  primary,
  white,
  grey,
  center,
}: PProps) => {
  const PStyles: Array<StyleProp<any>> = [styles.P, styles.PDefault];

  if (secondary) PStyles.push(styles.PSecondary);
  if (primary) PStyles.push(styles.PPrimary);
  if (white) PStyles.push(styles.PWhite);
  if (grey) PStyles.push(styles.PGrey);
  if (center) PStyles.push(styles.PCenter);

  return <Text style={PStyles}>{children}</Text>;
};

const styles = StyleSheet.create({
  P: {
    fontSize: 16,
    fontWeight: '400',
  },
  PDefault: {
    color: Colors.BLACK,
  },
  PWhite: {
    color: Colors.WHITE,
  },
  PPrimary: {
    color: Colors.PRIMARY,
  },
  PSecondary: {
    color: Colors.SECONDARY,
  },
  PGrey: {
    color: Colors.GREY,
  },
  PCenter: {
    textAlign: 'center',
  },
});
