import { injectable } from 'inversify';
import 'reflect-metadata';
import React from 'react';
import graphAPI from '../api/graphAPI';
import caregiversActions from '../providers/actions/caregiversActions';
import { getUsernameOrPreferredUsername } from '../utils/helpers/validations/getUsernameOrPreferredUsername';

@injectable()
export class CaregiverService {
  constructor(private readonly caregiversDispatch: React.Dispatch<any>) {}

  async getCaregiversFromDepartment(id: number) {
    const response = await graphAPI.caregivers.getCaregiversFromDepartment(id).catch((error) => {
      console.log(error);
    });
    if (response) {
      const sortedResponse = response.data.getCaregiversFromDepartment.sort((a: any, b: any) => {
        if (getUsernameOrPreferredUsername(a).toLowerCase() < getUsernameOrPreferredUsername(b).toLowerCase()) {
          return -1;
        }
        if (getUsernameOrPreferredUsername(a).toLowerCase() > getUsernameOrPreferredUsername(b).toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.caregiversDispatch(caregiversActions.getCaregiversFromDepartment(sortedResponse));
    }
  }
}
