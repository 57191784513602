import graphClient from '../graphClient/graphClient';
import getDepartmentsOfHospitalQuery from './graphCaregiversQueries/getDepartmentsOfHospitalQuery';

const getDepartmentsOfHospitalGraph = async (id: number) => {
  return await graphClient.query({
    query: getDepartmentsOfHospitalQuery,
    variables: { id },
  });
};

export default getDepartmentsOfHospitalGraph;
