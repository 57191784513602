import actionObject from './actionObjects';

import { ActionParameterDataType, ActionPayloadType } from '../../types/actionTypes';

const userActions = {
  //objects (actions strings & payload)
  getProfile: (payload: ActionPayloadType): ActionParameterDataType => {
    return actionObject.create('getProfile', payload);
  },
  createError: (payload: ActionPayloadType): ActionParameterDataType => {
    return actionObject.create('createError', payload);
  },
  resetUserState: () => {
    return actionObject.create('resetUserState');
  },
  //actions strings
  GET_PROFILE: actionObject.create('getProfile').type,
  USER_ERROR: actionObject.create('userError').type,
  RESET_USER_STATE: actionObject.create('resetUserState').type,
};

export default userActions;
