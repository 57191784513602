import { gql } from '@apollo/client';

const getDepartmentsOfHospitalQuery = gql`
  query getDepartmentsOfHospital($id: Int!) {
    getDepartmentsOfHospital(id: $id) {
      id
      name
      color
      hospital {
        id
      }
    }
  }
`;

export default getDepartmentsOfHospitalQuery;
