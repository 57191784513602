import React from 'react';
import Router from './src/router/Router';
import { Provider as PaperProvider } from 'react-native-paper';
import SnackBarNetwork from './src/components/feedbacks/snackBar/snackBarNetwork';
import AppContextProvider from './src/providers/AppContextProvider';
// @ts-ignore
import { stopReportingRuntimeErrors } from "react-error-overlay";
import {Platform} from "react-native";

if (process.env.NODE_ENV === "development" && Platform.OS === "web") {
    stopReportingRuntimeErrors(); // disables error overlays
}


export default function App() {
  return (
      <PaperProvider>
        <AppContextProvider>
          <>
            <SnackBarNetwork />
            <Router />
          </>
        </AppContextProvider>
      </PaperProvider>
  );
}

