import React from 'react';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Title } from '../../../components/texts';
import { StyleSheet } from 'react-native';
import { InputText } from '../../../components/inputs/InputText';
import { Button } from '../../../components/buttons';
import RNPickerSelect from 'react-native-picker-select';
import { useCaregiversContext } from '../../../providers/CaregiversProvider';
import { useAuthContext } from '../../../providers/AuthProvider';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import { useNavigation } from '@react-navigation/native';
import { ROUTES } from '../../../router/Routes';
import { Container } from '../../../layouts';
import { useUserContext } from '../../../providers/UserProvider';

const AddCaregiverScreen = () => {
  const authContext = useAuthContext();
  const { displaySnackBar } = useSnackBarContext();
  const caregiverContext = useCaregiversContext();
  const userContext = useUserContext();
  const { navigate } = useNavigation();

  const [loading, setLoading] = React.useState(false);
  const [jobs, setJobs] = React.useState([]);
  const [name, setName] = React.useState('');
  const [firstname, setFirstName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [job, setJob] = React.useState<number>(0);
  const [department, setDepartment] = React.useState(0);
  const [hospitalId, setHospitalId] = React.useState(0);

  React.useEffect(() => {
    caregiverContext.getJobList().then((response: any) => {
      if (response) {
        setJobs(
          response.data.getJobList.map((job: any) => {
            return { label: job.acronym, value: job.id };
          })
        );
      }
    });
  }, []);

  React.useEffect(() => {
    caregiverContext.getDepartmentsOfHospital().then((response: any) => {
      if (response) {
        setHospitalId(response.data.getDepartmentsOfHospital[0].hospital.id);
      }
    });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const response = await authContext.signup({
      username,
      name,
      firstname,
      jobId: +job,
      departmentIds: [+userContext.userState.profile.currentDepartment.id],
      hospitalId,
    });
    if (response) {
      displaySnackBar('Soignant créé avec succès', 'SUCCESS');
      setLoading(false);
      navigate(ROUTES.SERVICE_SCREEN.name);
    } else {
      displaySnackBar('Ce compte ne peut pas être créé', 'ERROR');
      setLoading(false);
    }
  };

  return (
    <MainLayout serviceCard backButton>
      <ModuleContentView>
        <Title h2>Ajouter un soignant</Title>
        <Container flexCol alignCenter justifyCenter>
          <InputText label={'Nom'} value={name} onChangeText={(value) => setName(value)} />
          <InputText label={'Prénom'} value={firstname} onChangeText={(value) => setFirstName(value)} />
          <InputText label={'Identifiant'} value={username} onChangeText={(value) => setUsername(value)} />
          <RNPickerSelect
            onValueChange={(value) => setJob(value)}
            items={jobs}
            placeholder={{
              label: 'Poste',
            }}
            style={pickerSelectStyles}
          />
          <Button label={'Ajouter un soignant'} large mvL onPress={handleSubmit} loading={loading} />
        </Container>
      </ModuleContentView>
    </MainLayout>
  );
};

export { AddCaregiverScreen };

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
  inputAndroid: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
});
