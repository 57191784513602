import graphClient from '../graphClient/graphClient';
import getJobListQuery from './graphCaregiversQueries/getJobListQuery';

const getJobListGraph = async () => {
  return await graphClient.query({
    query: getJobListQuery,
  });
};

export default getJobListGraph;
