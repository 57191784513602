
import React from 'react';
import { Image, View } from 'react-native';
import { Colors } from '../../styles/theme';
import { EvilIcons } from '@expo/vector-icons';
import { Title } from '../texts';
import Svg from '../images/Svg';
import { PriorityEnum } from '../../types/enums/PriorityEnum';
import { StatusEnum } from '../../types/enums/StatusEnum';
import MainCardContainer from './MainCardContainer';
import { Container } from '../../layouts';
import { Label } from '../texts/Label';
import { getPriority } from '../../utils/helpers/getPriority';
import getHourFromDate from "../../utils/helpers/getHourFromDate";

interface requestCardInterface {
  icon: string;
  title: string;
  date: string;
  haveComment: boolean;
  category: string;
  status: StatusEnum;
  priority: PriorityEnum;
  id: number;
  position: any;
  stickToHeader?: boolean;
  fromLockScreen?: boolean;
}

/**
 *
 * @param icon
 * @param date
 * @param haveComment
 * @param category
 * @param priority
 * @param status
 * @param id
 * @param position
 * @param stickToHeader
 * @constructor
 */
const RequestCard = ({
                       icon,
                       date,
                       haveComment,
                       category,
                       priority,
                       status,
                       position,
                       stickToHeader,
                       fromLockScreen,
                     }: requestCardInterface) => {
  let backgroundColor = Colors.REQUEST_CARDS[priority].light;
  if (status === StatusEnum.PROCESSING) backgroundColor = Colors.REQUEST_CARDS.NEUTRE.light;

  const renderCategory = (category: any) => {
    if (category.parent?.name?.fr) {
      return `${category.parent?.name?.fr} - ${category.name?.fr}`;
    } else {
      return `${category.name?.fr}`;
    }
  };

  const getIcon = (priority: PriorityEnum) => {
    if (priority === PriorityEnum.EMERGENCY)
      if (icon) return <Svg icon={icon} />;
      else
        return <Image source={require('../../assets/images/requests/urgency.png')} style={{ width: 80, height: 80 }} />;
    else return <Svg icon={icon} />;
  };

  const takeAt = new Date(date);

  return (
      <MainCardContainer stickToHeader={stickToHeader} backgroundColor={backgroundColor}>
        <Container flexRow alignCenter justifyCenter flex={1}>
          {getIcon(priority)}
        </Container>
        <Container flexCol flex={2} alignStart justifyBetween>
          <View>
            <Title h2 mvXS color={Colors.REQUEST_CARDS[priority].dark}>
              {`${position?.room?.name}  ${position?.name}`}
            </Title>
          </View>
          {!fromLockScreen && <Label>{renderCategory(category)}</Label>}
          {fromLockScreen && <Label>{getPriority(priority)}</Label>}

          <Container flexRow alignCenter justifyBetween>
            <Container flexRow alignCenter justifyStart>
              <EvilIcons name="clock" size={24} color="black" />
              <Title h3 mvXS>
                  {`${getHourFromDate(takeAt)}`}
              </Title>
            </Container>
            {haveComment && priority !== PriorityEnum.EMERGENCY ? (
                <Container flexRow alignCenter justifyStart>
                  <EvilIcons name="comment" size={24} color="black" />
                  <Title h3 mvXS>
                    1
                  </Title>
                </Container>
            ) : null}
          </Container>
        </Container>
      </MainCardContainer>
  );
};

export default RequestCard;