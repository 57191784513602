import actionObject from './actionObjects';

const chatActions = {
  //objects (actions strings & payload)
  storePassword: (payload: any) => actionObject.create('storePassword', payload),
  setMessages: (payload: any) => {
    return actionObject.create('setMessages', payload);
  },
  setOldMessages: (payload: any) => {
    return actionObject.create('setOldMessages', payload);
  },
  setNewMessage: (payload: any) => actionObject.create('setNewMessage', payload),

  //actions strings
  STORE_PASSWORD: actionObject.create('storePassword').type,
  SET_MESSAGES: actionObject.create('setMessages').type,
  SET_OLD_MESSAGES: actionObject.create('setOldMessages').type,
  SET_NEW_MESSAGE: actionObject.create('setNewMessage').type,
};

export default chatActions;
