import React from 'react';
import { Composer, ComposerProps, IMessage, SendProps } from 'react-native-gifted-chat';
import { Platform } from 'react-native';

export const customChatComposer = (
  props: ComposerProps & {
    // GiftedChat passes its props to all of its `render*()`
    onSend: SendProps<IMessage>['onSend'];
    text: SendProps<IMessage>['text'];
  }
) => (
  <Composer
    {...props}
    textInputProps={{
      ...props.textInputProps,
      blurOnSubmit: Platform.OS === 'web',
      onSubmitEditing:
        Platform.OS === 'web'
          ? () => {
              if (props.text && props.onSend) {
                props.onSend({ text: props.text.trim() }, true);
              }
            }
          : undefined,
    }}
  />
);
