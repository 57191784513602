import React from 'react';
import { HospiTeam } from '../../../modules/hospiteam/HospiTeam.module';
import { View } from 'react-native';
import HeaderModule from '../../../modules/dashboard/Header.module';
import { useCaregiversContext } from '../../../providers/CaregiversProvider';
import { useUserContext } from '../../../providers/UserProvider';
import { isSmallScreen } from '../../../utils/helpers/isSmallScreen';
import { useFocusEffect } from '@react-navigation/native';
import { useChatContext } from '../../../providers/ChatProvider';
//dd
const ChatScreen = () => {
  const caregiversContext = useCaregiversContext();
  const userContext = useUserContext();
  const chatContext = useChatContext();

  const [serviceInfos, setServiceInfos] = React.useState({
    name: '',
    caregivers_number: 0,
  });

  React.useEffect(() => {
    caregiversContext.getCaregiversFromDepartment();
    setServiceInfos({
      name:
        userContext.userState.profile.currentDepartment.name.fr ||
        userContext.userState.profile.currentDepartment.name.FR,
      caregivers_number: caregiversContext.caregiversState.caregivers.length,
    });
  }, [userContext.userState?.profile?.currentDepartment?.id, caregiversContext.caregiversState?.caregivers?.length]);

  useFocusEffect(
    React.useCallback(() => {
      chatContext.setNumberOfNewMessages(0);
      chatContext.setLastIdMessageToStorage().catch((err) => {
        console.log(err);
      });
    }, [])
  );

  useFocusEffect(
    React.useCallback(() => {
      chatContext.getPassword().then(() => {
        chatContext.getMessages(1).then(() => {});
      });
    }, [])
  );

  return (
    <>
      <View>
        <HeaderModule backButton={true} serviceInfos={serviceInfos} retracted={isSmallScreen()} />
      </View>
      <HospiTeam />
    </>
  );
};

export { ChatScreen };
