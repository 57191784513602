import React from 'react';
import { featuresReducer, initialFeaturesState } from './reducers/featuresReducer';
import { FeaturesService } from '../services/FeaturesFleeping.service';
import featuresActions from './actions/featuresAction';

interface FeaturesContextType {
  featuresState: any;
  getFeaturesEnabled: () => Promise<any>;
}

const FeaturesContext = React.createContext<FeaturesContextType>({} as FeaturesContextType);

const FeaturesProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [featuresState, featuresDispatch] = React.useReducer(featuresReducer, initialFeaturesState);
  const featuresFleepingService = new FeaturesService(featuresDispatch);

  const getFeaturesEnabled = async () => {
    const featuresEnabled = await featuresFleepingService.getFeaturesEnabled();
    if (featuresEnabled) {
      await setFeaturesEnabled(featuresEnabled.data.getFeaturesForCaregiver);
    }
  };

  const setFeaturesEnabled = async (features: Array<any>) => {
    const newFeaturesState = {
      communication: false,
      hospiteam: false,
      information: false,
      isInitialized: true,
    };

    features.map((feature) => {
      // @ts-ignore
      newFeaturesState[`${feature.feature}`] = true;
    });

    featuresDispatch(featuresActions.setFeatures({ newFeaturesState }));
  };

  return (
    <FeaturesContext.Provider
      value={{
        featuresState,
        getFeaturesEnabled,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

const useFeaturesContext = (): FeaturesContextType => {
  return React.useContext(FeaturesContext);
};

export { FeaturesProvider, useFeaturesContext };
