import { gql } from '@apollo/client';

export const updatePasswordMutation = gql`
  mutation updatePasswordCaregiver($UpdatePasswordInput: UpdatePasswordInput!) {
    updatePasswordCaregiver(UpdatePasswordInput: $UpdatePasswordInput) {
      success
      error
      username
    }
  }
`;
