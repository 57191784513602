import { gql } from '@apollo/client';

const getPositionQueries = gql`
  query getPosition($id: Int!) {
    getPosition(id: $id) {
      id
      createdAt
      updatedAt
      enabled
      name
      occupied
      uniqueId
      patient {
        name
        id
      }
      room {
        name
      }
    }
  }
`;

export default getPositionQueries;
