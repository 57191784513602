import graphUser from './graphUser';
import graphDemands from './graphDemands';
import graphPositions from './graphPositions';
import graphCaregivers from './graphCaregivers';
import graphAuth from './graphAuth';
import chatGraph from './graphChat';
import featureGraph from './graphFeatures';

const graphAPI = {
  user: graphUser,
  demands: graphDemands,
  positions: graphPositions,
  caregivers: graphCaregivers,
  auth: graphAuth,
  chat: chatGraph,
  features: featureGraph,
};

export default graphAPI;
