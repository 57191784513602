import graphClient from '../graphClient/graphClient';
import { ISignUp } from '../../../types/authTypes';
import { FetchResult } from '@apollo/client';
import { signUpMutation } from './query/signupMutation';

const signUpGraph = async (SignUpInput: ISignUp): Promise<FetchResult<any> | void> => {
  return await graphClient.mutate({ mutation: signUpMutation, variables: { SignUpInput } }).catch((error) => {
    console.log('gql error => ', error.graphQLErrors);
  });
};

export default signUpGraph;
