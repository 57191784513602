import React from 'react';
import { ICON } from '../../../env.json';
import { Image } from 'react-native';

interface SvgInterface {
  icon: string;
  height?: string;
  width?: string;
}

const Svg = ({ icon, height, width }: SvgInterface) => {
  const uri = `${ICON}${icon}`;

  return <Image source={{ uri }} style={{ height: 80, width: 80 }} />;
};

export default Svg;
