import { useNavigation } from '@react-navigation/native';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ROUTES } from '../../router/Routes';
import { Radius } from '../../styles/theme';
import { Title } from '../texts';
import React from 'react';
import { Container } from '../../layouts';
import { Entypo } from '@expo/vector-icons';
const CaregiverLifiCard = ({ name, id, caregiver, connected, lastPosition }: any) => {
  const { navigate } = useNavigation();
  return (
      <TouchableOpacity
          onPress={() =>
              navigate(ROUTES.UPDATE_CAREGIVER_SCREEN.name, {
                caregiver: caregiver,
              })
          }
          key={id}
      >
        <View style={styles.caregiverContainer}>
          <Container flexCol alignStart justifyStart>
            <Container alignCenter justifyStart flexRow>
              <Image source={require('../../assets/images/requests/iconUser.png')} style={{ width: 35, height: 35 }} />
              <Title h3 primary mvXS mhS>
                {name}
              </Title>
            </Container>
            <Container alignCenter justifyStart flexRow>
              <Image source={require('../../assets/images/requests/iconService.png')} style={{ width: 35, height: 35 }} />
              <Title h3 mvXS mhS>
                {caregiver.job?.name?.fr}
              </Title>
            </Container>
            {/*<Container alignCenter justifyStart flexRow>*/}
            {/*  <Image source={require('../../assets/images/location.png')} style={{ width: 20, height: 20 }} />*/}
            {/*  <Title h3 mvXS mhS>*/}
            {/*    {lastPosition}*/}
            {/*  </Title>*/}
            {/*</Container>*/}
          </Container>
          {/*<Container alignEnd flex={1}>*/}
          {/*  <Entypo name="circle" size={24} color={connected ? 'green' : 'red'} />*/}
          {/*</Container>*/}
        </View>
      </TouchableOpacity>
  );
};
export { CaregiverLifiCard };

const styles = StyleSheet.create({
  caregiverContainer: {
    backgroundColor: '#ffffff',
    borderRadius: Radius.SMALL,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 20,
    marginTop: 10,
  },
});