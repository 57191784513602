import * as React from 'react';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { Snackbar } from 'react-native-paper';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import RequestCard from '../../cards/RequestCard';
import { ROUTES } from '../../../router/Routes';
import { useNavigation } from '@react-navigation/native';
import PositionRequestCard from '../../cards/PositionRequestCard';

const NotificationSnackbar = () => {
  const {
    showNotificationSnackbar,
    hideNotificationSnackBar,
    notificationSnackbarContent,
    notificationSnackbarType,
  } = useSnackBarContext();
  const { navigate } = useNavigation();
  const snackBarStyle: Array<any> = [styles.snackBar];

  const renderSnackbar = () => {
    if (Platform.OS !== 'web') return;
    if (notificationSnackbarType === 'Demand') {
      return (
        <TouchableOpacity
          onPress={() => navigate(ROUTES.REQUEST_DETAILS_SCREEN.name, { id: notificationSnackbarContent.id })}
          style={{ width: 400 }}
        >
          <RequestCard
            icon={notificationSnackbarContent.category.icon}
            title={notificationSnackbarContent.title}
            date={notificationSnackbarContent.createdAt}
            haveComment={notificationSnackbarContent.comment}
            category={notificationSnackbarContent.category}
            status={notificationSnackbarContent.status.toUpperCase()}
            priority={notificationSnackbarContent.priority.toUpperCase()}
            id={notificationSnackbarContent.id}
            position={notificationSnackbarContent.position}
          />
        </TouchableOpacity>
      );
    } else if (notificationSnackbarType === 'Position') {
      return (
        <TouchableOpacity
          onPress={() =>
            navigate(ROUTES.NEW_PATIENT_SCREEN.name, { positionRequestId: notificationSnackbarContent.id })
          }
        >
          <PositionRequestCard
            date={notificationSnackbarContent.createdAt}
            position={notificationSnackbarContent.position.name}
            room={notificationSnackbarContent.position.room.name}
          />
        </TouchableOpacity>
      );
    }
  };

  return (
    <Snackbar
      visible={showNotificationSnackbar}
      onDismiss={hideNotificationSnackBar}
      duration={4000}
      style={snackBarStyle}
      wrapperStyle={styles.snackBarContainer}
    >
      {notificationSnackbarContent && renderSnackbar()}
    </Snackbar>
  );
};

const styles = StyleSheet.create({
  snackBarContainer: {
    position: 'absolute',
    top: 170,
    width: 400,
    zIndex: 999,
    right: 25,
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
  },
  snackBar: {
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
  },
});

export default NotificationSnackbar;
