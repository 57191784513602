import getMyPatientProfileGraph from './getMyCaregiverProfileGraph';
import addDeviceToUserGraph from './addDeviceToUserGraph';
import changeCurrentDepartmentGraph from './changeCurrentDepartmentGraph';
import { getServiceHistoryGraph } from './getServiceHistory';
import updateMyCaregiverProfileGraph from './updateMyCaregiverProfile';
import deleteDeviceUserGraph from './deleteDeviceUserGraph';

export default {
  getMyPatientProfile: getMyPatientProfileGraph,
  addDeviceToUser: addDeviceToUserGraph,
  changeDepartment: changeCurrentDepartmentGraph,
  getServiceHistory: getServiceHistoryGraph,
  updateMyCaregiverProfile: updateMyCaregiverProfileGraph,
  deleteUsersDevice: deleteDeviceUserGraph,
};
