import graphClient from '../graphClient/graphClient';
import { getAllPositionsQueries } from './graphPositionsQueries/getPositionsQueries';

type FilterPositionsInput = {
  enabled?: boolean;
  roomId?: number;
  departmentId: number;
  caregiverId?: number;
};

export const getAllPositionsGraph = async (filter: FilterPositionsInput) => {
  return await graphClient.query({
    query: getAllPositionsQueries,
    variables: { filter: { departmentId: filter.departmentId } },
  });
};
