import signInGraph from './signInGraph';
import signUpGraph from './signUpGraph';
import { refreshTokenGraph } from './refreshTokenGraph';
import { updatePasswordGraph } from './updatePasswordGraph';
import { setCredentialsCaregiverGraph } from './setCredentialsCaregiverGraph';

export default {
  signIn: signInGraph,
  signUp: signUpGraph,
  refreshToken: refreshTokenGraph,
  updatePassword: updatePasswordGraph,
  setCredentialsCaregiver: setCredentialsCaregiverGraph,
};
