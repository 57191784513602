import graphClient from '../graphClient/graphClient';
import updateMyCaregiverProfileMutation from './graphUserQueries/updateMyCaregiverProfileMutation';

type UpdateCaregiverInput = {
  pinCode?: string;
  id?: number;
  name?: string;
  firstname?: string;
  departmentIds?: Array<number>;
  jobId?: number;
  username?: string;
};

const updateMyCaregiverProfileGraph = async (updateCaregiverInput: UpdateCaregiverInput) => {
  return await graphClient.mutate({
    mutation: updateMyCaregiverProfileMutation,
    variables: { updateCaregiverInput: updateCaregiverInput },
  });
};

export default updateMyCaregiverProfileGraph;
