import userAction from '../actions/userActions';

import { ActionParameterDataType } from '../../types/actionTypes';

interface UserStateType {
  isLoading: boolean;
  profile: any;
  error: any;
}

export const initialUserState: UserStateType = {
  isLoading: true,
  profile: null,
  error: Error,
};

export const userReducer = (state = initialUserState, action: ActionParameterDataType): UserStateType => {
  switch (action.type) {
    case userAction.GET_PROFILE:
      return {
        ...state,
        isLoading: false,
        profile: action.payload,
      };
    case userAction.USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case userAction.RESET_USER_STATE:
      return {
        ...state,
        profile: null,
      };
    default:
      return state;
  }
};
