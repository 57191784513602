import graphClient from '../graphClient/graphClient';

import getPositionQueries from './graphPositionsQueries/getPositionQueries';

const getPositionGraph = async (id: number) => {
  return await graphClient.query({
    query: getPositionQueries,
    variables: { id: id },
  });
};

export default getPositionGraph;
