import { gql } from '@apollo/client';

const updateMyCaregiverProfileMutation = gql`
  mutation updateMyCaregiverProfile($updateCaregiverInput: UpdateCaregiverInput!) {
    updateMyCaregiverProfile(updateCaregiverInput: $updateCaregiverInput) {
      id
    }
  }
`;
export default updateMyCaregiverProfileMutation;
