import { gql } from '@apollo/client';

const deleteUsersDeviceQuery = gql`
  mutation deleteUsersDevice {
    deleteUsersDevice {
      deleted
    }
  }
`;

export default deleteUsersDeviceQuery;
