import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { isSmallScreen } from '../../utils/helpers/isSmallScreen';
import { useAuthContext } from '../../providers/AuthProvider';
import { Container } from '../../layouts';

interface PinCodeKeyboardInterface {
  updateCode: (number: number) => void;
  deleteLastCode: () => void;
}

const PinCodeKeyboard = ({ updateCode, deleteLastCode }: PinCodeKeyboardInterface) => {
  const authContext = useAuthContext();

  return (
    <Container flexCol alignCenter justifyCenter w100>
      <Container alignCenter justifyCenter flexRow w100>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(1)}>
          <Text style={styles.number}>1</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(2)}>
          <Text style={styles.number}>2</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(3)}>
          <Text style={styles.number}>3</Text>
        </TouchableOpacity>
      </Container>
      <Container alignCenter justifyCenter flexRow w100>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(4)}>
          <Text style={styles.number}>4</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(5)}>
          <Text style={styles.number}>5</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(6)}>
          <Text style={styles.number}>6</Text>
        </TouchableOpacity>
      </Container>
      <Container alignCenter justifyCenter flexRow w100>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(7)}>
          <Text style={styles.number}>7</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(8)}>
          <Text style={styles.number}>8</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(9)}>
          <Text style={styles.number}>9</Text>
        </TouchableOpacity>
      </Container>
      <Container alignCenter justifyCenter flexRow w100>
        <TouchableOpacity style={styles.buttons} onPress={() => authContext.logout()}>
          <AntDesign name="logout" size={34} color="red" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => updateCode(0)}>
          <Text style={styles.number}>0</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttons} onPress={() => deleteLastCode()}>
          <Ionicons name="backspace-outline" size={34} color="red" />
        </TouchableOpacity>
      </Container>
    </Container>
  );
};

export default PinCodeKeyboard;

const styles = StyleSheet.create({
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    height: isSmallScreen() ? 80 : 100,
  },
  number: {
    fontSize: 34,
  },
});
