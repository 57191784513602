import graphClient from '../graphClient/graphClient';

import updateCaregiverMutation from './graphCaregiversQueries/updateCaregiverMutation';

type UpdateCaregiverInput = {
  pinCode: string;
  id: number;
  name: string;
  firstname: string;
  departmentIds: Array<number>;
  jobId: number;
  username: string;
};

const updateCaregiverGraph = async (updateCaregiverInput: UpdateCaregiverInput) => {
  return await graphClient.mutate({
    mutation: updateCaregiverMutation,
    variables: { updateCaregiverInput: updateCaregiverInput },
  });
};

export default updateCaregiverGraph;
