import { gql } from '@apollo/client';

const deleteUserCaregiverQuery = gql`
  mutation deleteUserCaregiver($DeleteUserInput: DeleteUserInput!) {
    deleteUserCaregiver(DeleteUserInput: $DeleteUserInput) {
      deleted
      error
    }
  }
`;

export default deleteUserCaregiverQuery;
