import { gql } from '@apollo/client';

const getMyCaregiverProfileQuery = gql`
  query GetMyCaregiverProfile {
    getMyCaregiverProfile {
      id
      pinCode
      name
      firstname
      passwordState
      user {
        username
        preferredUsername
        role
      }
      departments {
        id
        name
        color
        selfLockingDelay
        enabled
      }
      job {
        description
        name
        acronym
      }
      currentDepartment {
        id
        name
        color
        selfLockingDelay
      }
      hospital {
        id
      }
    }
  }
`;

export default getMyCaregiverProfileQuery;
