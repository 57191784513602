import graphClient from '../graphClient/graphClient';

import getDemandsByIdQueries from './graphDemandsQueries/getDemandsByIdQueries';

const getDemandByIdGraph = async (id: number) => {
  return await graphClient.query({
    query: getDemandsByIdQueries,
    variables: { demandId: id },
  });
};

export default getDemandByIdGraph;
