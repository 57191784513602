import React from 'react';
import { ActivityIndicator, ImageStyle, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { Colors, Radius, Sizing, Spacing } from '../../styles/theme';
import { ButtonLabel } from '../texts';

interface ButtonProps {
  onPress?: () => void;
  rounded?: boolean;
  outlined?: boolean;
  secondary?: boolean;
  success?: boolean;
  small?: boolean;
  large?: boolean;
  medium?: boolean;
  acceptrequest?: boolean;
  label: string;
  loading?: boolean;
  mvS?: boolean;
  mvM?: boolean;
  mvL?: boolean;
  mhS?: boolean;
  mhM?: boolean;
  mhL?: boolean;
  flex_1?: boolean;
  disable?: boolean;
  noBorder?: boolean;
  noBg?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  onPress,
  rounded,
  outlined,
  secondary,
  success,
  small,
  large,
  medium,
  acceptrequest,
  label,
  mvS,
  mvM,
  mvL,
  mhS,
  mhM,
  mhL,
  flex_1,
  disable,
  loading,
  noBg,
  noBorder,
}: ButtonProps) => {
  const buttonStyles: (ViewStyle | ImageStyle | undefined)[] = [styles.button, styles.primary, styles.large];

  if (secondary) buttonStyles.push(styles.secondary);
  if (success) buttonStyles.push(styles.success);
  if (rounded) buttonStyles.push(styles.rounded);
  if (outlined) buttonStyles.push(styles.outlined);
  if (large) buttonStyles.push(styles.large);
  if (medium) buttonStyles.push(styles.medium);
  if (acceptrequest) buttonStyles.push(styles.acceptrequest);
  if (small) buttonStyles.push(styles.small);
  if (mvL) buttonStyles.push(styles.mvL);
  if (mvM) buttonStyles.push(styles.mvM);
  if (mvS) buttonStyles.push(styles.mvS);
  if (mhL) buttonStyles.push(styles.mhL);
  if (mhM) buttonStyles.push(styles.mhM);
  if (mhS) buttonStyles.push(styles.mhS);
  if (flex_1) buttonStyles.push(styles.flex_1);
  if (disable) buttonStyles.push(styles.disable);
  if (noBg) buttonStyles.push(styles.noBg);
  if (noBorder) buttonStyles.push(styles.noBorder);

  return (
    <TouchableOpacity style={buttonStyles} onPress={loading ? () => null : onPress}>
      {loading ? (
        <ActivityIndicator size={'large'} color={'white'} />
      ) : (
        <ButtonLabel secondary={secondary} outlined={outlined} disable={disable}>
          {label}
        </ButtonLabel>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: Radius.SMALL,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  primary: {
    backgroundColor: Colors.PRIMARY,
    borderColor: Colors.PRIMARY,
  },
  secondary: {
    backgroundColor: Colors.SECONDARY,
    borderColor: Colors.SECONDARY,
  },
  success: {
    backgroundColor: Colors.SUCCESS,
    borderColor: Colors.SUCCESS,
  },
  disable: {
    backgroundColor: Colors.WHITE,
    borderColor: Colors.WHITE,
  },
  rounded: {
    borderRadius: Radius.LARGE,
  },
  outlined: {
    backgroundColor: Colors.WHITE,
    borderWidth: 1,
  },
  large: {
    ...Sizing.BUTTON_LARGE,
  },
  medium: {
    ...Sizing.BUTTON_MEDIUM,
  },
  acceptrequest: {
    ...Sizing.BUTTON_ACCEPTREQUEST,
  },
  small: {
    ...Sizing.BUTTON_SMALL,
    ...Spacing.PADDING_HORIZONTAL_SMALL,
  },
  flex_1: {
    ...Sizing.BUTTON_FLEX_1,
    borderRadius: Radius.MEDIUM,
    marginHorizontal: 5,
  },
  mvL: {
    ...Spacing.MARGIN_VERTICAL_LARGE,
  },
  mvM: {
    ...Spacing.MARGIN_VERTICAL_MEDIUM,
  },
  mvS: {
    ...Spacing.MARGIN_VERTICAL_SMALL,
  },
  mhL: {
    ...Spacing.MARGIN_HORIZONTAL_LARGE,
  },
  mhM: {
    ...Spacing.MARGIN_HORIZONTAL_MEDIUM,
  },
  mhS: {
    ...Spacing.MARGIN_HORIZONTAL_SMALL,
  },
  labelPrimary: {
    color: Colors.PRIMARY,
  },
  labelSecondary: {
    color: Colors.SECONDARY,
  },
  labelDefault: {
    color: Colors.WHITE,
  },
  lottie: {
    width: 70,
    height: 100,
    backgroundColor: 'red',
  },
  noBorder: {
    borderWidth: 0,
  },
  noBg: {
    backgroundColor: 'transparent',
  },
});
