import { gql } from '@apollo/client';

const getFeaturesForCaregiverQuery = gql`
  query getFeaturesForCaregiver {
    getFeaturesForCaregiver {
      createdAt
      feature
      id
      updatedAt
    }
  }
`;

export default getFeaturesForCaregiverQuery;
