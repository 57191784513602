import actionObject from './actionObjects';
import { ActionPayloadType, ActionParameterDataType } from '../../types/actionTypes';

const userRequestsActions = {
  //objects (actions strings & payload)
  getDepartmentRequestPositions: (payload: any) => actionObject.create('getDepartmentRequestPositions', payload),
  updateDepartmentRequestPositions: (payload: any) => actionObject.create('updateDepartmentRequestPositions', payload),
  getDepartmentPositions: (payload: any) => actionObject.create('getDepartmentPositions', payload),

  //actions strings
  GET_DEPARTMENT_REQUEST_POSITIONS: actionObject.create('getDepartmentRequestPositions').type,
  UPDATE_DEPARTMENT_REQUEST_POSITIONS: actionObject.create('updateDepartmentRequestPositions').type,
  GET_DEPARTMENT_POSITIONS: actionObject.create('getDepartmentPositions').type,
};

export default userRequestsActions;
