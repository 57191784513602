import React from 'react';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Title } from '../../../components/texts';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { InputText } from '../../../components/inputs/InputText';
import { Button } from '../../../components/buttons';
import RNPickerSelect from 'react-native-picker-select';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useCaregiversContext } from '../../../providers/CaregiversProvider';
import { getDifferences } from '../../../utils/helpers/getDifferencesBetweenArrays';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import { ROUTES } from '../../../router/Routes';

const UpdateCaregiverScreen = () => {
  const caregiverContext = useCaregiversContext();
  const { displaySnackBar } = useSnackBarContext();
  const { navigate } = useNavigation();

  const [username, setUsername] = React.useState('');
  const [name, setName] = React.useState('');
  const [firstname, setFirstName] = React.useState('');
  const [departmentsOfHospital, setDepartmentsOfHospital] = React.useState<Array<any>>([]);
  const [departments, setDepartments] = React.useState<Array<any>>([]);
  const [currentDepartment, setCurrentDepartment] = React.useState();
  const [job, setJob] = React.useState('');
  const [jobs, setJobs] = React.useState([]);
  const caregiver: any = useRoute();

  React.useEffect(() => {
    caregiverContext.getJobList().then((response: any) => {
      if (response) {
        setJobs(
          response.data.getJobList.map((job: any) => {
            return { label: job.acronym, value: job.id };
          })
        );
      }
    });
  }, []);

  React.useEffect(() => {
    caregiverContext.getDepartmentsOfHospital().then((response: any) => {
      if (response) {
        setDepartmentsOfHospital(
          response.data.getDepartmentsOfHospital.map((department: any) => {
            return department;
          })
        );
      }
    });
  }, []);

  React.useEffect(() => {
    if (caregiver.params) {
      caregiverContext.getCaregiverProfile(caregiver.params.caregiver.id).then((caregiver: any) => {
        const data = caregiver.data.getCaregiverProfile;

        setFirstName(data.firstname);
        setName(data.name);
        setJob(data.job.id);
        setUsername(data.user.username);
        setDepartments(data.departments);
        setCurrentDepartment(data.currentDepartment);
      });
    }
  }, [caregiver]);

  const handleSubmit = async () => {
    const response = await caregiverContext.updateCaregiver({
      name,
      firstname,
      departmentIds: departments.map((department: any) => department.id),
      jobId: job,
      username,
      pinCode: caregiver.params.caregiver.pinCode,
      id: caregiver.params.caregiver.id,
    });
    if (response) {
      displaySnackBar('Modifications enregistrées', 'SUCCESS');
    }
  };

  const updateCaregiverDepartments = async (department: any) => {
    const newState = departments.filter((a) => a !== department);
    setDepartments(newState);
  };

  const getAvailableDepartmentsOfHospital = () => {
    return getDifferences(departmentsOfHospital, departments);
  };

  const deleteUserCaregiver = async () => {
    const response = await caregiverContext.deleteUserCaregiver(username);
    if (response.data.deleteUserCaregiver.deleted) {
      displaySnackBar('Soignant supprimé', 'SUCCESS');
      navigate(ROUTES.SERVICE_SCREEN.name);
    } else {
      displaySnackBar("Le doignant n'a pas été supprimé", 'ERROR');
    }
  };

  return (
    <MainLayout serviceCard backButton white>
      <ModuleContentView>
        <Title h2 center>
          Mettre à jour un soignant
        </Title>
        <View style={styles.inputsContainer}>
          <InputText label={'Nom'} value={name} onChangeText={(value) => setName(value)} />
          <InputText label={'Prénom'} value={firstname} onChangeText={(value) => setFirstName(value)} />
          {/*<InputText label={'Identifiant'} value={username} onChangeText={(value) => setUsername(value)} />*/}
          <Title h3>Poste du soignant</Title>
          <RNPickerSelect
            onValueChange={(value) => setJob(value)}
            value={job}
            items={jobs}
            placeholder={{
              label: 'Poste',
            }}
            style={pickerSelectStyles}
          />
          <TouchableOpacity onPress={() => deleteUserCaregiver()}>
            <Title h3 secondary>
              Supprimer le soignant
            </Title>
          </TouchableOpacity>
          <Button label={'Mettre à jour'} large mvL onPress={() => handleSubmit()} />
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export { UpdateCaregiverScreen };

const styles = StyleSheet.create({
  inputsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  servicesContainer: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 15,
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
  inputAndroid: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.8,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
});
