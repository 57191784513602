import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import MainLayout from '../../layouts/dashboard/MainLayout';
import { Button } from '../../components/buttons';
import CaregiversOverview from '../../modules/dashboard/department/CaregiversOverview.module';
import BedsOverview from '../../modules/dashboard/department/BedsOverview.module';
import ModuleContentView from '../../layouts/dashboard/ModuleContentView';
import { amIAllowed } from '../../utils/helpers/amIAllowed';
import { RolesEnum } from '../../types/enums/RolesEnum';
import { useUserContext } from '../../providers/UserProvider';

const ServiceScreen = (): React.ReactElement => {
  const userContext = useUserContext();
  const [mode, setMode] = React.useState('beds');
  return (
    <MainLayout serviceCard lockButton={Platform.OS === 'web'}>
      <ModuleContentView>
        <View style={styles.buttonsContainer}>
          <Button label={'Lits'} disable={mode === 'caregivers'} flex_1 onPress={() => setMode('beds')} />
          {amIAllowed(userContext.userState.profile?.user?.role.toUpperCase(), RolesEnum.MANAGER) ? (
            <Button label={'Soignants'} flex_1 disable={mode === 'beds'} onPress={() => setMode('caregivers')} />
          ) : null}
        </View>
        {mode === 'caregivers' ? <CaregiversOverview /> : <BedsOverview />}
      </ModuleContentView>
    </MainLayout>
  );
};

export { ServiceScreen };

const styles = StyleSheet.create({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginVertical: 20,
  },
});
