import { gql } from '@apollo/client';

export const setCredentialsCaregiverMutation = gql`
  mutation resetCredentialsCaregiver($ResetCredentialsInput: ResetCredentialsInput!) {
    resetCredentialsCaregiver(ResetCredentialsInput: $ResetCredentialsInput) {
      success
      error
      username
    }
  }
`;
