import React from 'react';

interface SnackBarContextType {
  displaySnackBar: any;
  hideSnackBar: any;
  showSnackBar: boolean;
  snackBarContent: string;
  snackBarType: SnackBarType;
  snackBarDuration: number;
  setSnackBarDuration: (duration: number) => void;
  displayNotificationSnackBar: any;
  showNotificationSnackbar: boolean;
  hideNotificationSnackBar: any;
  notificationSnackbarContent: any;
  notificationSnackbarType: string;
}

enum SnackBarType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  NEUTRAL = 'NEUTRAL',
}

const SnackBarContext = React.createContext({} as SnackBarContextType);

const SnackBarProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = React.useState<string>('');
  const [snackBarType, setSnackBarType] = React.useState<SnackBarType>(SnackBarType.NEUTRAL);
  const [snackBarDuration, setSnackBarDuration] = React.useState<number>(2000);
  const [showNotificationSnackbar, setShowNotificationSnackbar] = React.useState<boolean>(false);
  const [notificationSnackbarContent, setNotificationSnackbarContent] = React.useState<any>(null);
  const [notificationSnackbarType, setNotificationSnackbarType] = React.useState<string>('');

  const displaySnackBar = (content: string, type: SnackBarType) => {
    setSnackBarContent(content);
    setSnackBarType(type);
  };

  const hideSnackBar = () => {
    setShowSnackBar(false);
    setSnackBarContent('');
    setSnackBarType(SnackBarType.NEUTRAL);
  };

  const displayNotificationSnackBar = (content: any, type: string) => {
    setNotificationSnackbarType(type);
    setNotificationSnackbarContent(content);
  };

  const hideNotificationSnackBar = () => {
    setNotificationSnackbarContent(null);
    setShowNotificationSnackbar(false);
    setNotificationSnackbarType('');
  };

  React.useEffect(() => {
    if (snackBarContent !== '') setShowSnackBar(true);
  }, [snackBarContent]);

  React.useEffect(() => {
    if (notificationSnackbarContent !== null) setShowNotificationSnackbar(true);
  }, [notificationSnackbarContent]);

  return (
    <SnackBarContext.Provider
      value={{
        displaySnackBar,
        hideSnackBar,
        showSnackBar,
        snackBarContent,
        snackBarType,
        snackBarDuration,
        setSnackBarDuration,
        displayNotificationSnackBar,
        showNotificationSnackbar,
        hideNotificationSnackBar,
        notificationSnackbarContent,
        notificationSnackbarType,
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackBarContext = () => {
  return React.useContext(SnackBarContext);
};

export { SnackBarProvider, useSnackBarContext };
