import actionObject from './actionObjects';
import { ActionPayloadType, ActionParameterDataType } from '../../types/actionTypes';

const userRequestsActions = {
  //objects (actions strings & payload)
  getCaregiversFromDepartment: (payload: any) => actionObject.create('getCaregiversFromDepartment', payload),

  //actions strings
  GET_CAREGIVERS_FROM_DEPARTMENT: actionObject.create('getCaregiversFromDepartment').type,
};

export default userRequestsActions;
