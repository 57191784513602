import React, { useReducer } from 'react';
import graphAPI from '../api/graphAPI';
import { WithActionType } from '../types/actionTypes';
import { caregiversReducer, initialCaregiversState } from './reducers/caregiversReducer';
import { useUserContext } from './UserProvider';
import { CaregiverService } from '../services/Caregiver.service';
import { getUsernameOrPreferredUsername } from '../utils/helpers/validations/getUsernameOrPreferredUsername';

interface CaregiversContextType {
  getCaregiversFromDepartment: () => any;
  caregiversState: any;
  updateCaregiver: (caregiverProfile: any) => any;
  getJobList: any;
  getDepartmentsOfHospital: any;
  getCaregiverProfile: (id: number) => any;
  deleteUserCaregiver: (username: string) => any;
}

const CaregiversContext = React.createContext<CaregiversContextType>({} as CaregiversContextType);

const CaregiversProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const userContext = useUserContext();
  const [caregiversState, caregiversDispatch]: WithActionType = useReducer(
    caregiversReducer as any,
    initialCaregiversState
  );
  const caregiverService = new CaregiverService(caregiversDispatch);

  const getCaregiversFromDepartment = async () =>
    caregiverService.getCaregiversFromDepartment(userContext.userState.profile.currentDepartment.id);

  const updateCaregiver = async (caregiverProfile: any) => {
    return await graphAPI.caregivers.updateCaregiver(caregiverProfile).catch((e) => {
      console.log('error :', e);
    });
  };

  const getJobList = async () => {
    return await graphAPI.caregivers.getJobList().catch((e) => {
      console.log('error :', e);
    });
  };

  const getDepartmentsOfHospital = async () => {
    return await graphAPI.caregivers.getDepartmentsOfHospital(userContext.userState.profile.hospital.id).catch((e) => {
      console.log('error :', e);
    });
  };

  const getCaregiverProfile = async (id: number) => {
    return await graphAPI.caregivers.getCaregiverProfile(id);
  };

  const deleteUserCaregiver = async (username: string) => {
    return await graphAPI.caregivers.deleteUserCaregiver(username);
  };

  return (
    <CaregiversContext.Provider
      value={{
        getCaregiversFromDepartment,
        caregiversState,
        updateCaregiver,
        getJobList,
        getDepartmentsOfHospital,
        getCaregiverProfile,
        deleteUserCaregiver,
      }}
    >
      {children}
    </CaregiversContext.Provider>
  );
};

const useCaregiversContext = () => {
  return React.useContext(CaregiversContext);
};

export { CaregiversProvider, useCaregiversContext };
