import React from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import HeaderModule from '../../modules/dashboard/Header.module';
import { HeaderModuleInterface } from '../../types/headerTypes/HeaderModuleType';
import { Colors } from '../../styles/theme';
import MainSnackbar from '../../components/feedbacks/snackBar/snackBar';
import NotificationSnackbar from '../../components/feedbacks/notifications_web/Notification';

const MainLayout = ({
  children,
  expanded,
  profileCard,
  requestCardHeaderData,
  serviceCard,
  backButton,
  white,
  title,
  backToTop,
  lockButton,
}: HeaderModuleInterface): React.ReactElement => {
  const stylesList: Array<any> = [];
  const childrenContainer: Array<any> = [Platform.OS === 'web' ? styles.webStyle : null];
  if (white) stylesList.push(styles.bgWhite);
  const scrollViewRef = React.useRef() as any;

  return (
    <>
      <ScrollView
        onContentSizeChange={() => {
          if (backToTop) scrollViewRef.current?.scrollToEnd({ animated: true });
        }}
        ref={scrollViewRef as any}
      >
        <HeaderModule
          expanded={expanded}
          profileCard={profileCard}
          requestCardHeaderData={requestCardHeaderData}
          serviceCard={serviceCard}
          backButton={backButton}
          title={title}
          lockButton={lockButton}
        />

        <View style={childrenContainer}>{children}</View>
      </ScrollView>
      {<MainSnackbar />}
      {<NotificationSnackbar />}
    </>
  );
};

const styles = StyleSheet.create({
  bgWhite: {
    backgroundColor: Colors.WHITE,
  },
  webStyle: {
    maxWidth: 1300,
    minWidth: 600,
    display: 'flex',
    margin: 'auto',
  },
});

export default MainLayout;
