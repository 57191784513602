export const isContainUpper = (password: string) => {
  let result = false;
  password.split('').forEach((letter) => {
    if (letter === letter.toUpperCase() && /[a-zA-Z]/.test(letter)) result = true;
  });
  return result;
};

export const IsContainSymbol = (password: string) => {
  return /[|\\/~^:,;._?!&%$@€£¥()'÷="*+\-]/.test(password);
};

export const IsContainNumber = (password: string) => {
  return /\d+/g.test(password);
};

export const passwordVerification = (password: string): boolean => {
  return password.length >= 8 && isContainUpper(password) && IsContainSymbol(password) && IsContainNumber(password);
};

export const passwordConfirmationVerification = (password: string, passwordVerification: string): boolean => {
  return password === passwordVerification;
};
