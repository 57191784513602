import { gql } from '@apollo/client';

const acceptPositionRequestMutation = gql`
  mutation acceptPositionRequest($positionRequestId: Int!) {
    acceptPositionRequest(positionRequestId: $positionRequestId) {
      status
    }
  }
`;
export default acceptPositionRequestMutation;
