import React from 'react';
import { ImageStyle, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { Colors, Radius, Spacing } from '../../styles/theme';
import { AntDesign, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

interface ButtonProps {
  onPress?: () => void;
  rounded?: boolean;
  outlined?: boolean;
  secondary?: boolean;
  next?: boolean;
  close?: boolean;
  add?: boolean;
  qr?: boolean;
  small?: boolean;
}

export const RoundButton: React.FunctionComponent<ButtonProps> = ({
  onPress,
  rounded,
  outlined,
  secondary,
  next,
  close,
  add,
  qr,
  small,
}: ButtonProps) => {
  const buttonStyles: (ViewStyle | ImageStyle)[] = [styles.button, styles.primary];

  if (secondary) buttonStyles.push(styles.secondary);
  if (rounded) buttonStyles.push(styles.rounded);
  if (outlined) buttonStyles.push(styles.outlined);
  if (small) buttonStyles.push(styles.small);

  const getIcon = () => {
    if (next) return <AntDesign name="right" size={25} color="white" />;
    if (close) return <Ionicons name="close-outline" size={22} color={Colors.SECONDARY} />;
    if (add) return <AntDesign name="plus" size={25} color="white" />;
    if (qr) return <MaterialCommunityIcons name="qrcode-scan" size={24} color="white" />;
  };

  return (
    <TouchableOpacity style={buttonStyles} onPress={onPress}>
      {getIcon()}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...Spacing.MARGIN_VERTICAL_SMALL,
    width: 70,
    height: 70,
    borderRadius: 35,
  },
  primary: {
    backgroundColor: Colors.PRIMARY,
    borderColor: Colors.PRIMARY,
  },
  secondary: {
    backgroundColor: Colors.SECONDARY,
    borderColor: Colors.SECONDARY,
  },
  rounded: {
    borderRadius: Radius.LARGE,
  },
  outlined: {
    backgroundColor: Colors.WHITE,
    borderWidth: 1,
  },
  small: {
    width: 45,
    height: 45,
    borderWidth: 3,
  },
});
