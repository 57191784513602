import { gql } from '@apollo/client';

const rejectPositionRequestMutation = gql`
  mutation rejectPositionRequest($positionRequestId: Int!) {
    rejectPositionRequest(positionRequestId: $positionRequestId) {
      status
    }
  }
`;
export default rejectPositionRequestMutation;
