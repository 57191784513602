import { gql } from '@apollo/client';

const addDeviceToUserMutation = gql`
  mutation addDeviceToUser($deviceInput: DeviceInput!) {
    addDeviceToUser(deviceInput: $deviceInput) {
      userId
    }
  }
`;
export default addDeviceToUserMutation;
