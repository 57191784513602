import graphClient from '../graphClient/graphClient';

import rejectPositionRequestMutation from './graphPositionsQueries/rejectPositionRequestMutation';

const rejectPositionRequestGraph = async (positionRequestId: number) => {
  return await graphClient.mutate({
    mutation: rejectPositionRequestMutation,
    variables: { positionRequestId },
  });
};

export default rejectPositionRequestGraph;
