import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Snackbar } from 'react-native-paper';
import { Colors } from '../../../styles/theme';
import { useAppStateContext } from '../../../providers/AppstateProvider';

const SnackBarNetwork = () => {
  const { isConnected } = useAppStateContext();
  const snackBarStyle: Array<any> = [styles.snackBar, styles.snackBarError];

  return (
    <Snackbar
      visible={!isConnected}
      onDismiss={() => null}
      duration={1000000}
      style={snackBarStyle}
      wrapperStyle={styles.snackBarContainer}
    >
      Veuillez vérifier votre connexion internet
    </Snackbar>
  );
};

const styles = StyleSheet.create({
  snackBarContainer: {
    position: 'absolute',
    top: 50,
    zIndex: 999,
  },
  snackBar: {
    padding: 10,
  },
  snackBarNeutral: {},
  snackBarSuccess: {
    backgroundColor: Colors.SUCCESS,
  },
  snackBarError: {
    backgroundColor: Colors.DANGER,
  },
});

export default SnackBarNetwork;
