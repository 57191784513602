import { gql } from '@apollo/client';

const changeDepartment = gql`
  mutation changeDepartment($departmentId: Int!) {
    changeDepartment(departmentId: $departmentId) {
      id
    }
  }
`;
export default changeDepartment;
