//TODO trouver une solution pour le problem de type avatar.text
//@ts-nocheck

import React from 'react';

import { ROUTES } from '../../router/Routes';

import { Paragraph, Title } from '../../components/texts';
import { AuthBottomContainer, Container } from '../../layouts/';
import { useNavigation } from '@react-navigation/native';
import PinCodeKeyboard from '../../components/pinCode/PinCodeKeyboard';
import { PinCodeIndicator } from '../../components/pinCode/PinCodeIndicator';
import {isMediumScreen, isSmallScreen} from '../../utils/helpers/isSmallScreen';
import { useUserContext } from '../../providers/UserProvider';
import asyncStorage from '@react-native-async-storage/async-storage';
import { Avatar } from 'react-native-paper';
import { getUsernameOrPreferredUsername } from '../../utils/helpers/validations/getUsernameOrPreferredUsername';
import { Platform, TouchableOpacity } from 'react-native';
import { getWebviewOrLink } from '../../utils/helpers/getWebviewOrLink';
import { useAppStateContext } from '../../providers/AppstateProvider';
import {AntDesign} from "@expo/vector-icons";

const PinCodeScreen: React.FunctionComponent = () => {
  const userContext = useUserContext();
  const appState = useAppStateContext();
  const { navigate, goBack } = useNavigation();
  const [code, setCode] = React.useState<Array<number>>([]);
  const [errors, setErrors] = React.useState<boolean | string>(false);

  const updateCode = (value: number) => {
    setErrors(false);
    if (code.length < 4) setCode((code) => [...code, value]);
  };

  const updateCodeWithKeyboard = React.useCallback((event) => {
    switch (event.key) {
      case 'Backspace':
        deleteLastCode();
        break;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        updateCode(parseInt(event.key));
        break;
    }
  }, []);

  const deleteLastCode = () => {
    setErrors(false);
    const newCode = [...code];
    newCode.pop();
    setCode(newCode);
  };

  const submitCode = async () => {
    const userCodePin = await asyncStorage.getItem('pincode');
    if (userCodePin) {
      if (code.join('') === userCodePin) {
        setCode([]);
        appState.setNeedToTapePinCode(false);
        navigate(ROUTES.HOME_SCREEN.name);
      } else {
        setErrors('Le code est incorrect');
        setCode([]);
      }
    } else {
      setErrors('Une erreur est survenue, veuillez contacter un administrateur');
      setCode([]);
    }
  };

  React.useEffect(() => {
    code.length === 4 ? submitCode() : null;
  }, [code]);

  React.useEffect(() => {
    if (Platform.OS !== 'web') return;
    window.addEventListener('keydown', updateCodeWithKeyboard);
    return () => window.removeEventListener('keydown', updateCodeWithKeyboard);
  }, [updateCodeWithKeyboard]);

  return (
    <Container primary flex={1}>
      <Container primary flex={1} flexRow  alignCenter justifyBetween={Platform.OS === "web"} justifyCenter={Platform.OS !== "web"} >
        {
          Platform.OS === "web" && (
                <Container alignCenter justifyCenter>
                  <TouchableOpacity onPress={() => goBack()}>
                    <AntDesign name="arrowleft" size={32} color="white" />
                  </TouchableOpacity>
                </Container>
            )
        }

        <Container alignCenter justifyCenter primary height={isMediumScreen() ? 150 : 300}>
          {
            !isMediumScreen() &&  <Avatar.Text
                  size={isSmallScreen() ? 80 : 100}
                  label={getUsernameOrPreferredUsername(userContext.userState.profile)[0].toUpperCase()}
              />
          }
          <Title h2 white>
            Bonjour, {getUsernameOrPreferredUsername(userContext.userState.profile)}
          </Title>
          {errors ? (
            <Title h3 white>
              {errors}
            </Title>
          ) : (
            <PinCodeIndicator code={code} />
          )}
        </Container>
        {
          Platform.OS === "web" && (
                <Container>
                  {''}
                </Container>
            )
        }

      </Container>
      <AuthBottomContainer>
        <PinCodeKeyboard updateCode={updateCode} deleteLastCode={deleteLastCode} />
        <TouchableOpacity
          onPress={() =>
            getWebviewOrLink({
              navigate,
              link: 'https://www.hospitalink.fr/soignant/faq',
              route: ROUTES.CONTACT_SCREEN.name,
            })
          }
        >
          <Paragraph primary>Besoin d'aide ?</Paragraph>
        </TouchableOpacity>
      </AuthBottomContainer>
    </Container>
  );
};
export { PinCodeScreen };
