import React from 'react';
import { enableScreens } from 'react-native-screens';

import StackNavigator from './StackNavigator';

enableScreens();
const Router = () => {
  return <StackNavigator />;
};

export default Router;
