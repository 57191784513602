import actionObject from './actionObjects';

import { ActionPayloadType, ActionDataType } from '../../types/actionTypes';

const authActions: ActionDataType = {
  //objects (actions strings & payload)
  error: actionObject.create('error'),
  logout: actionObject.create('logout'),
  pending: actionObject.create('pending'),
  refreshToken: (payload: ActionPayloadType) => actionObject.create('refreshToken', payload),
  login: (payload: ActionPayloadType) => {
    return actionObject.create('login', payload);
  },
  setNewPassword: (payload: ActionPayloadType) => {
    return actionObject.create('setNewPassword', payload);
  },
  setNewPinCode: (payload: ActionPayloadType) => {
    return actionObject.create('setNewPinCode', payload);
  },
  //actions strings
  LOGIN: actionObject.create('login').type,
  ERROR: actionObject.create('error').type,
  LOGOUT: actionObject.create('logout').type,
  PENDING: actionObject.create('pending').type,
  REFRESH_TOKEN: actionObject.create('refreshToken').type,
  SET_NEW_PASSWORD: actionObject.create('setNewPassword').type,
  SET_NEW_PIN_CODE: actionObject.create('setNewPINCode').type,
};

export default authActions;
