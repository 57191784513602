export const MARGIN_HORIZONTAL_SMALL = {
  marginHorizontal: 10,
};
export const MARGIN_HORIZONTAL_MEDIUM = {
  marginHorizontal: 20,
};
export const MARGIN_HORIZONTAL_LARGE = {
  marginHorizontal: 40,
};
export const MARGIN_VERTICAL_SMALL = {
  marginVertical: 10,
};
export const MARGIN_VERTICAL_MEDIUM = {
  marginVertical: 20,
};
export const MARGIN_VERTICAL_LARGE = {
  marginVertical: 40,
};

export const PADDING_HORIZONTAL_SMALL = {
  paddingHorizontal: 15,
};
export const PADDING_HORIZONTAL_MEDIUM = {
  paddingHorizontal: 50,
};
export const PADDING_HORIZONTAL_LARGE = {
  paddingHorizontal: 70,
};
export const PADDING_VERTICAL_SMALL = {
  paddingVertical: 15,
};
export const PADDING_VERTICAL_MEDIUM = {
  paddingVertical: 50,
};
export const PADDING_VERTICAL_LARGE = {
  paddingVertical: 70,
};
