import React from 'react';
import { WebView } from 'react-native-webview';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import { Dimensions, Platform } from 'react-native';
import { isSmallScreen } from '../../../utils/helpers/isSmallScreen';
import * as Linking from 'expo-linking';

const ConditionGeneraleScreen: React.FunctionComponent = () => {
  const screenHeight = Dimensions.get('screen').height - (isSmallScreen() ? 140 : 190);

  React.useEffect(() => {
    Platform.OS === 'web' && Linking.openURL('https://charte.hospitalink.fr/');
  }, []);

  return (
    <MainLayout backButton white title={'Conditions générales de vente'}>
      <WebView
        originWhitelist={['*']}
        source={{ uri: 'https://charte.hospitalink.fr/' }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        startInLoadingState={true}
        style={{ width: '100%', height: screenHeight }}
      />
    </MainLayout>
  );
};
export { ConditionGeneraleScreen };
