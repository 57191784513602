import { gql } from '@apollo/client';

export const refreshTokenMutation = gql`
  mutation refreshTokenCaregiver($RefreshTokenInput: RefreshTokenInput!) {
    refreshTokenCaregiver(RefreshTokenInput: $RefreshTokenInput) {
      AuthenticationResult {
        AccessToken
        IdToken
        RefreshToken
        ExpiresIn
      }
    }
  }
`;
