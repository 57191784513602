import { IResetCredentialsCaregiverInput, IResetPassword, ISignIn, ISignUp, IUpdatePassword } from '../types/authTypes';
import { FetchResult } from '@apollo/client';
import React from 'react';
import graphAPI from '../api/graphAPI';
import authActions from '../providers/actions/authActions';
import { container } from '../inversify.config';

import { StorageService } from './Storage.service';
import { TYPES } from '../DI/TYPES';
import jwt_decode from 'jwt-decode';
import { TokenInfosType } from '../types/tokenTypes';

export class AuthService {
  private asyncStorage: StorageService;

  constructor(private readonly authDispatch: React.Dispatch<any>) {
    this.asyncStorage = container.get<StorageService>(TYPES.storageService);
  }

  async signIn(signInPayload: ISignIn): Promise<FetchResult<any> | void> {
    const dataFromSignIn = await graphAPI.auth.signIn(signInPayload);
    if (dataFromSignIn && dataFromSignIn.data) {
      const { IdToken, RefreshToken, AccessToken } = dataFromSignIn.data.signinCaregiver.AuthenticationResult;
      await this.asyncStorage.setTokens({ access: AccessToken, refresh: RefreshToken, id: IdToken });
      this.authDispatch(authActions.login({ IdToken, RefreshToken }));
      console.log({dataFromSignIn});
      return dataFromSignIn;
    } else {
      throw new Error('Connexion Impossible');
    }
  }

  async logout(): Promise<void> {
    await this.asyncStorage.removeRtoTokens();
    this.authDispatch(authActions.logout);
  }

  async signUp(signupPayload: ISignUp): Promise<FetchResult<any> | void> {
    console.log("signupPayload : ", signupPayload);
    return await graphAPI.auth.signUp(signupPayload).catch((e: new () => any) => {
      throw new e();
    });
  }

  async resetPassword(resetPasswordPayload: IResetPassword): Promise<unknown> {
    return new Promise(() => console.log('resetPasswordPayload'));
  }

  async updatePassword(updatePasswordPayload: IUpdatePassword): Promise<FetchResult<any> | void> {
    const { AccessToken } = await this.asyncStorage.getTokens();
    return graphAPI.auth.updatePassword({ AccessToken, ...updatePasswordPayload }).catch((e: new () => any) => {
      throw new e();
    });
  }

  async refreshToken(): Promise<boolean> {
    const { RefreshToken } = await this.asyncStorage.getTokens();

    if (RefreshToken) {
      const refreshedTokens = await graphAPI.auth.refreshToken({ RefreshToken });
      if (refreshedTokens && refreshedTokens.data) {
        const { IdToken, AccessToken } = refreshedTokens.data.refreshTokenCaregiver.AuthenticationResult;
        await this.asyncStorage.setTokens({ access: AccessToken, id: IdToken });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async checkIdTokenValidity(): Promise<boolean> {
    const { IdToken } = await this.asyncStorage.getTokens();
    if (!IdToken) return false;
    try {
      const tokenInfos: TokenInfosType = jwt_decode(IdToken);
      const currentDate = new Date().getTime();

      return tokenInfos.exp * 1000 > currentDate;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async signInWithToken(): Promise<void | boolean> {
    const isTokenValid = await this.checkIdTokenValidity();
    if (isTokenValid) {
      const { IdToken, RefreshToken } = await this.asyncStorage.getTokens();
      this.authDispatch(authActions.login({ IdToken, RefreshToken }));
      return true;
    } else {
      const refreshedTokens = await this.refreshToken();
      if (refreshedTokens) {
        const { IdToken, RefreshToken } = await this.asyncStorage.getTokens();
        this.authDispatch(authActions.login({ IdToken, RefreshToken }));
        return true;
      } else {
        this.authDispatch(authActions.logout);
        return false;
      }
    }
  }

  async setCredentialsCaregiver(
    credentialsCaregiverInput: IResetCredentialsCaregiverInput
  ): Promise<FetchResult<any> | void> {
    return graphAPI.auth.setCredentialsCaregiver({ ...credentialsCaregiverInput }).catch((e: new () => any) => {
      throw new e();
    });
  }
}
