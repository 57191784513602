import graphClient from '../graphClient/graphClient';
import { IUpdatePassword } from '../../../types/authTypes';
import { FetchResult } from '@apollo/client';
import { updatePasswordMutation } from './query/updatePasswordMutation';

export const updatePasswordGraph = async (UpdatePasswordInput: IUpdatePassword): Promise<FetchResult<any> | void> => {
  return await graphClient
    .mutate({ mutation: updatePasswordMutation, variables: { UpdatePasswordInput: UpdatePasswordInput } })
    .catch((error) => {
      console.log('gql error => ', error.graphQLErrors);
    });
};
