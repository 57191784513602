import { ApolloQueryResult } from '@apollo/client';
import graphClient from '../graphClient/graphClient';
import getMyCaregiverProfileQuery from './graphUserQueries/getMyCaregiverProfileQuery';

import { GetCaregiverProfileResponseType } from '../../../types/graphQlResponseTypes';

const getMyCaregiverProfileGraph = async (): Promise<ApolloQueryResult<GetCaregiverProfileResponseType>> => {
  return await graphClient.query({ query: getMyCaregiverProfileQuery });
};

export default getMyCaregiverProfileGraph;
