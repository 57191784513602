import React from 'react';
import { Entypo, EvilIcons } from '@expo/vector-icons';
import { Title } from '../texts';
import { StatusEnum } from '../../types/enums/StatusEnum';
import { getUsernameOrPreferredUsername } from '../../utils/helpers/validations/getUsernameOrPreferredUsername';
import MainCardContainer from './MainCardContainer';
import { Container } from '../../layouts';
import { Label } from '../texts/Label';
import { View } from 'react-native';
import { PriorityEnum } from '../../types/enums/PriorityEnum';
import getHourFromDate from "../../utils/helpers/getHourFromDate";

interface requestCardInterface {
  date: any;
  category: string;
  status: StatusEnum;
  priority: PriorityEnum;
  caregiver?: any;
  position?: any;
}

/**
 *
 * @param icon
 * @param date
 * @param category
 * @param status
 */
const HistoryCard = ({ date, caregiver, category, status, position, priority }: requestCardInterface) => {
  const takeAt = new Date(date);

    return (
    <MainCardContainer noPadding backgroundColor={'#e5faff'}>
      <Container flexRow alignCenter justifyCenter flex={1} backgroundColor={'lightgrey'} height={'100%'}>
        {caregiver ? (
          <Title h1 white>
            {getUsernameOrPreferredUsername(caregiver)[0].toUpperCase()}
          </Title>
        ) : (
          <Title h1 white>
            H
          </Title>
        )}
      </Container>
      <Container flexCol flex={3} alignStart justifyBetween paddingHorizontal={5}>
        <Title h2 mvXS secondary>
          {position ? `${position?.room?.name}  ${position?.name}`: 'Hospitalink'}
        </Title>

        <Label color={'white'}>{category}</Label>
        <Container flexRow alignCenter justifyBetween>
          <Container flexRow alignCenter justifyStart>
            <EvilIcons name="clock" size={24} color="black" />
            <Title h3 mvXS>
                {`${getHourFromDate(takeAt)}`}
            </Title>
          </Container>
        </Container>
      </Container>

      <View
        style={{
          height: 120,
          width: 120,
          position: 'absolute',
          right: -55,
          bottom: -55,
          backgroundColor: status.toUpperCase() === StatusEnum.TERMINATED ? 'green' : 'red',
          borderRadius: 70,
          display: 'flex',
        }}
      >
        {status.toUpperCase() === StatusEnum.TERMINATED ? (
          <Entypo name="check" size={30} color="white" style={{ marginTop: 20, marginLeft: 20 }} />
        ) : (
          <Entypo name="cross" size={30} color="white" style={{ marginTop: 20, marginLeft: 20 }} />
        )}
      </View>
    </MainCardContainer>
  );
};

export default HistoryCard;
