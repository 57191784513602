import React from 'react';
import { initialUserState, userReducer } from './reducers/userReducer';
import userActions from './actions/userActions';
import graphAPI from '../api/graphAPI';
import { useAuthContext } from './AuthProvider';
import { useAppStateContext } from './AppstateProvider';
import { storePincodeToLocalStorages } from '../utils/helpers/localStorage';
import { PasswordStatusEnum } from '../types/enums/PasswordStatusEnum';
import { Platform } from 'react-native';

interface UserContextType {
  getPatientProfile: () => Promise<void>;
  userNeedToEnterCodePin: boolean;
  userState: any;
  changeCurrentDepartment: (departmentId: number) => Promise<void>;
  getServiceHistory: (date?: any, departmentId?: number) => Promise<any>;
  updateMyCaregiverProfile: (updateCaregiverInput: any) => Promise<any>;
  getCaregiverProfile: () => Promise<any>;
}

const UserContext = React.createContext<UserContextType>({} as UserContextType);

const UserProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const authContext = useAuthContext();
  const appStateContext = useAppStateContext();
  const [userState, userDispatch] = React.useReducer(userReducer, initialUserState);
  const [userNeedToEnterCodePin, setUserNeedToEnterCodePin] = React.useState(false);

  React.useEffect(() => {
    if (authContext.authState.isConnected && userState.profile?.passwordState === PasswordStatusEnum.NEED_CHANGE)
      return;
    setUserNeedToEnterCodePin(appStateContext.appStateVisible === 'active');
  }, [appStateContext.appStateVisible]);

  React.useEffect(() => {
    if (authContext.authState.isConnected) {
      getCaregiverProfile()
        .then((response) => {
          console.log('caregiver profile => ', response);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log('reset user');
      userDispatch(userActions.resetUserState());
    }
  }, [authContext.authState.isConnected]);

  React.useEffect(() => {
    if (Platform.OS !== 'web') return;
    if (!authContext.authState.isConnected) return;
    if (!userState.profile?.currentDepartment?.selfLockingDelay) return;
    const selfLockingDelay = setInterval(() => {
      appStateContext.setNeedToTapePinCode(true);
    }, userState.profile.currentDepartment.selfLockingDelay * 1000);

    return () => clearInterval(selfLockingDelay);
  }, [authContext.authState.isConnected, userState.profile?.currentDepartment?.selfLockingDelay]);

  const getCaregiverProfile = async (): Promise<void> => {
    try {
      const user = await graphAPI.user.getMyPatientProfile();
      if (user) {
        userDispatch(userActions.getProfile(user.data.getMyCaregiverProfile));
        await storePincodeToLocalStorages(user.data.getMyCaregiverProfile.pinCode);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changeCurrentDepartment = async (departmentId: number) => {
    const response = await graphAPI.user.changeDepartment(departmentId);
    if (response) {
      await getCaregiverProfile();
    }
  };

  const getServiceHistory = async (date?: any, departmentId?: number) => {
    const id = departmentId ? departmentId : userState.profile.currentDepartment.id;
    return await graphAPI.user.getServiceHistory(date, id);
  };

  const updateMyCaregiverProfile = async (updateCaregiverInput: any) => {
    const response = await graphAPI.user.updateMyCaregiverProfile(updateCaregiverInput).catch((e) => {
      console.log(e);
    });
    await getCaregiverProfile();
    return response;
  };

  return (
    <UserContext.Provider
      value={{
        getPatientProfile: getCaregiverProfile,
        userState,
        userNeedToEnterCodePin,
        changeCurrentDepartment,
        getServiceHistory,
        updateMyCaregiverProfile,
        getCaregiverProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = (): UserContextType => {
  return React.useContext(UserContext);
};

export { UserProvider, useUserContext };
