import { gql } from '@apollo/client';

const getDemandsByIdQueries = gql`
  query getDemand($demandId: Int!) {
    getDemand(demandId: $demandId) {
      id
      position {
        id
        name
        room {
          id
          name
        }
      }
      patient {
        id
        name
      }
      category {
        id
        name
        icon
        parent {
          name
          id
          icon
        }
      }
      takeBy {
        user {
          id
          username
        }
      }
      priority
      status
      intensity
      takeAt
      bodyPart {
        name
        icon
      }
      comment
      viewByCaregiver
      archived
      createdAt
    }
  }
`;

export default getDemandsByIdQueries;
