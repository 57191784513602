import graphClient from '../graphClient/graphClient';

import { getMessagesOfUserDepartmentQuery } from './query/getMessageOfUserDepartmentQuery';

const getMessagesOfDepartmentGraph = async (ChatMessageFilter: { page: number }) => {
  return await graphClient.query({
    query: getMessagesOfUserDepartmentQuery,
    variables: { ChatMessageFilter: ChatMessageFilter },
  });
};

export default getMessagesOfDepartmentGraph;
