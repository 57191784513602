import { gql } from '@apollo/client';

const getServiceDemandsQuery = gql`
  query getMyDemands($filter: FilterDemandInput!) {
    getDemands(filterDemandInput: $filter) {
      id
      position {
        id
        name

        room {
          id
          name
        }
      }
      patient {
        id
        name
      }
      category {
        id
        name
        icon
        parent {
          name
          id
          icon
        }
      }
      priority
      status
      intensity
      takeAt
      bodyPart {
        name
        icon
      }
      comment
      viewByCaregiver
      archived
      createdAt
    }
  }
`;

export default getServiceDemandsQuery;
