import getCaregiversFromDepartmentGraph from './getCaregiversFromDepartmentGraph';
import updateCaregiverGraph from './updateCaregiverGraph';
import getJobListGraph from './getJobListGraph';
import getDepartmentsOfHospitalGraph from './getDepartmentsOfHospitalGraph';
import getCaregiverProfileGraph from './getCaregiverProfileGraph';
import deleteUserCaregiverGraph from './deleteUserCaregiverGraph';

export default {
  getCaregiversFromDepartment: getCaregiversFromDepartmentGraph,
  updateCaregiver: updateCaregiverGraph,
  getJobList: getJobListGraph,
  getDepartmentsOfHospital: getDepartmentsOfHospitalGraph,
  getCaregiverProfile: getCaregiverProfileGraph,
  deleteUserCaregiver: deleteUserCaregiverGraph,
};
