import { PriorityEnum } from '../../types/enums/PriorityEnum';

export const getPriority = (priority: PriorityEnum) => {
  switch (priority) {
    case PriorityEnum.LOW:
      return 'Basse';
    case PriorityEnum.NORMAL:
      return 'Normal';
    case PriorityEnum.HIGH:
      return 'Haute';
    case PriorityEnum.EMERGENCY:
      return 'URGENT';
  }
};

export const getPriorityValue = (priority: PriorityEnum) => {
  switch (priority) {
    case PriorityEnum.LOW:
      return 1;
    case PriorityEnum.NORMAL:
      return 2;
    case PriorityEnum.HIGH:
      return 3;
    case PriorityEnum.EMERGENCY:
      return 4;
  }
};
