import graphClient from '../graphClient/graphClient';

import acceptPositionRequestMutation from './graphPositionsQueries/acceptPositionRequestMutation';
import { FetchResult } from '@apollo/client';

const acceptPositionRequestGraph = async (positionRequestId: number): Promise<FetchResult> => {
  return await graphClient.mutate({
    mutation: acceptPositionRequestMutation,
    variables: { positionRequestId },
  });
};

export default acceptPositionRequestGraph;
