import { gql } from '@apollo/client';

const updateCaregiverMutation = gql`
  mutation updateCaregiverProfile($updateCaregiverInput: UpdateCaregiverInput!) {
    updateCaregiverProfile(updateCaregiverInput: $updateCaregiverInput) {
      id
    }
  }
`;
export default updateCaregiverMutation;
